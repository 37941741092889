import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Event} from 'src/app/domain/models';
import {FractalService} from 'src/app/services/fractal/fractal.service';
import {Title} from '@angular/platform-browser';
import {NotificationService} from "../../services/notifications/notification.service";
import {checkModel} from "../../domain/model-utils";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  model: Event = new Event();
  events: Event[];

  constructor(
    private modalService: NgbModal,
    private fractalService: FractalService,
    private titleService: Title,
    private notifyService: NotificationService
  ) {
    titleService?.setTitle("Мероприятия")

  }

  ngOnInit() {
    this.getEvents();
  }

  open(content) {
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title'}).result.then(() => {
      this.createEvent();
    }, () => {
    });
  }

  getEvents(): void {
    this.fractalService.getEvents()
      .subscribe(res =>
        this.events = res.sort((a, b) => a.beginDate > b.beginDate ? -1 : 1)
      );
  }

  private createEvent() {
    if (checkModel(this.model)) {
      this.notifyService.showInfo("Создаем мероприятие...");
      this.fractalService.createEvent(this.model).subscribe(
        (res) => {
          this.getEvents();
          console.log(res);
          this.notifyService.showSuccess(res);
        },
        error => {
          this.notifyService.showError(error.error)
        }
      );
    } else {
      this.notifyService.showError("Возможно какое-то поле не заполнено!");
    }
  }
}
