<div class="container">
  <h1>Изменение группы</h1>
  <div>
    <button class="button" (click)="showOrHideUpdateDialog()">Открыть диалог с обновлением данных</button>
  </div>
  <form #heroForm="ngForm" [hidden]="!isUpdateEventEnabled">
    <div class="form-group">
      <label for="name">Название</label>
      <input type="text" class="form-control" id="name" required [(ngModel)]="model.name" name="name" #name="ngModel">
      <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
        Обязательное поле
      </div>
    </div>
    <div class="form-group">
      <label for="bib">bib</label>
      <input type="text" class="form-control" id="bib" required [(ngModel)]="model.bib" name="bib" #name="ngModel">
      <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
        Обязательное поле
      </div>
    </div>

    <div class="form-group">
      <label for="group">Группа</label>
      <select class="form-control" id="group"
      [(ngModel)]="currentGroup"
      name="currentGroup" ng-model="currentGroup" ng-options="x for x in groups">
      <option *ngFor="let c of groups" [ngValue]="c">{{c.name}}</option>
    </select>
    </div>
    <button type="button" class="button-main" (click)="save();">Сохранить</button>
  </form>

  <div>
    <button class="button" (click)="showOrHideCreateDialog()">Создать участника</button>
  </div>

  <h1 class="title">Участники</h1>
  <div class="heroes">
    <li *ngFor="let day of participants">
      <a routerLink="participants/{{day.id}}">
        <span>bib: {{day.bib}}</span>
        <div>
          <p>Имя : {{day.name}}</p>
          <p>Фамилия: {{day.surname}}</p>
          <p>Контрольное время старта: {{day.controlStartTime}}</p>
          <p>Дата рождения: {{day.birthDate}}</p>
          <p>Пол: {{day.gender}}</p>

        </div>
      </a>
  </div>
</div>
