import {Component, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';

import {faBars, faBook, faGear, faHamburger, faSearch, faUser,} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";
import {MenuItem} from "./domain/ui-models";
import {faLocation} from "@fortawesome/free-solid-svg-icons/faLocation";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app-component.scss'],
})
export class AppComponent {
  title = 'Fractal';
  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;

  constructor(
    public router: Router,
  ) {
  }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  events: string[] = [];
  opened: boolean = true;
  searchIcon = faSearch;
  faHamburger = faHamburger;
  faBars = faBars;

  userIcon = faUser;

  menuItems: Array<MenuItem> = [
    new MenuItem(
      Math.floor(Math.random() * 100).toString(),
      "Events",
      "/events",
      faBook,
      [],
    ),
    new MenuItem(
      Math.floor(Math.random() * 100).toString(),
      "Settings",
      "/settings",
      faGear,
      [
        new MenuItem(
          Math.floor(Math.random() * 100).toString(),
          "GPS",
          "/settings/gps",
          faLocation,
          []
        ),
        new MenuItem(
          Math.floor(Math.random() * 100).toString(),
          "Punch algorithms",
          "/settings/punch-algorithms",
          faLocation,
          []
        ),
        new MenuItem(
          Math.floor(Math.random() * 100).toString(),
          "Control points",
          "/settings/control-points",
          faLocation,
          []
        )
      ]
    ),
    // new MenuItem(
    //   Math.floor(Math.random() * 100).toString(),
    //   "Test",
    //   "/change",
    //   faAmbulance,
    //   []
    // )
  ]

  showLeftNav: boolean = true;

  showAndHideLeftNav() {
    this.showLeftNav = !this.showLeftNav;
  }

  handleClick(item) {
    if (item.url == "/change") {
    } else {
      this.router.navigateByUrl(item.url)
    }
  }

  restoreMenu() {

  }

  expand(item: MenuItem) {
    var index = this.menuItems.indexOf(item)

    this.menuItems[index].expanded = !this.menuItems[index].expanded
  }
}
