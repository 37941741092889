import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Group, Team, TeamsAndGroups, UpdateLinks} from 'src/app/domain/models';
import {FractalService} from 'src/app/services/fractal/fractal.service';
import {NotificationService} from "../../services/notifications/notification.service";

@Component({
  selector: 'app-participants-manage',
  templateUrl: './participants-manage.component.html',
  styleUrls: ['./participants-manage.component.scss']
})
export class ParticipantsManageComponent implements OnInit {
  participantId = '';
  modelTeam: ModelTeam = new ModelTeam();
  modelGroup: ModelGroup = new ModelGroup();
  groups: Group[];
  teamsAndGroups: TeamsAndGroups;
  teams: Team[];
  selectedGroup: Group;
  selectedTeam: Team;
  eventId = '';
  dayId = '';

  constructor(
    private route: ActivatedRoute,
    private api: FractalService,
    private notifyService: NotificationService
  ) {
    const eventId: string = this.route.snapshot.params.id1;
    this.eventId = eventId;
    const dayId: string = this.route.snapshot.params.id2;
    this.dayId = dayId;
  }

  ngOnInit(): void {
    this.api.getTeamsAndGroups(this.eventId, this.dayId).subscribe(g => {
      g.teams = g.teams.sort(this.compareTeams);
      g.groups = g.groups.sort(this.compareGroups);
      this.teamsAndGroups = g;
    });
  }

  compareTeams(a: Team, b: Team) {
    if (a.bib < b.bib) {
      return -1;
    }
    if (a.bib > b.bib) {
      return 1;
    }
    return 0;
  }

  compareGroups(a: Group, b: Group) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  updateTeam() {
    console.log(this.modelTeam);
    this.api.linkParticipantToOtherTeam(this.eventId, this.dayId, this.modelTeam.oldTeamId, new UpdateLinks(this.participantId, this.modelTeam.newTeamId)).subscribe(res => {
      this.notifyService.showSuccess(res);
    });
  }

  updateGroup() {
    console.log(this.modelGroup);
    console.log(this.selectedGroup);
    this.api.linkTeamToOtherGroup(this.eventId, this.dayId, this.modelGroup.teamId, new UpdateLinks(this.modelGroup.oldGroupId, this.modelGroup.newGroupId)).subscribe(res => {
      this.notifyService.showSuccess(res);
    });
  }
}


export class ModelTeam {
  oldTeamId: string;
  participantId: string;
  newTeamId: string;
}

export class ModelGroup {
  teamId: string;
  oldGroupId: string;
  newGroupId: string;
}
