import {Component, OnInit} from '@angular/core';
import {Group} from "../../domain/models";
import {ActivatedRoute} from "@angular/router";
import {FractalService} from "../../services/fractal/fractal.service";
import {Title} from "@angular/platform-browser";
import {NotificationService} from "../../services/notifications/notification.service";

@Component({
  selector: 'app-group-info',
  templateUrl: './group-info.component.html'
})
export class GroupInfoComponent implements OnInit {

  group: Group;
  groupId: string;

  constructor(
    private route: ActivatedRoute,
    private api: FractalService,
    private titleService: Title,
    private notifyService: NotificationService
  ) {
    this.groupId = this.route.snapshot.params.id;
    titleService.setTitle("Изменение группы")
  }

  ngOnInit(): void {
    this.api.getGroupById(this.groupId).subscribe(res => {
      this.group = res;
    });
  }

  save() {
    console.log(this.group);
    this.api.updateGroupById(this.groupId, this.group).subscribe(res => {
        this.notifyService.showSuccess("Обновлено")
      }
    )
  }

}
