import {Component} from '@angular/core';


@Component({
  selector: 'app-events-manage',
  templateUrl: './events-manage.component.html',
  styleUrls: ['./events-manage.component.scss']
})
export class EventsManageComponent {
  menuItems = [
    {
      title: 'Home',
      route: '/home'
    },
    {
      title: 'Products',
      route: '/products',
      submenu: [
        { title: 'Product 1', route: '/products/1' },
        { title: 'Product 2', route: '/products/2' },
        { title: 'Product 3', route: '/products/3' }
      ]
    },
    {
      title: 'Services',
      route: '/services',
      submenu: [
        { title: 'Service 1', route: '/services/1' },
        { title: 'Service 2', route: '/services/2' }
      ]
    },
    {
      title: 'Contact',
      route: '/contact'
    }
  ];
}
