import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Punch} from 'src/app/domain/models';
import {FractalService} from 'src/app/services/fractal/fractal.service';
import {Title} from '@angular/platform-browser';
import {NotificationService} from "../../services/notifications/notification.service";

@Component({
  selector: 'app-devices-manage',
  templateUrl: './devices-manage.component.html',
  styleUrls: ['./devices-manage.component.scss']
})
export class DevicesManageComponent implements OnInit {

  deviceId: string = '';
  punch: Punch = new Punch();
  logs: string = '';

  constructor(
    private route: ActivatedRoute,
    private api: FractalService,
    private titleService: Title,
    private notifyService: NotificationService
  ) {
    titleService?.setTitle('Управление устройствами');
  }

  ngOnInit(): void {
    this.punch.major = 52670;
    this.punch.timestamp = '2021-09-08T14:27:14Z';
    this.punch.uuid = '74278bda-b644-4520-8f0c-720eaf059935';
    this.punch.packetId = 'admin-add';
  }

  createPunch() {
    this.api.uploadPunch(this.deviceId, [this.punch]).subscribe(res => {
      res.success.forEach(x =>
        this.notifyService.showSuccess(x.message + ' - ' + x.status)
      );
      res.errors.forEach(x =>
        this.notifyService.showError(x.message + ' - ' + x.minor)
      )
    });
  }

  deletePunch() {
    console.log(this.deviceId);
    console.log(this.punch);
    this.api.deletePunch(this.deviceId, this.punch).subscribe(res => {
      this.notifyService.showSuccess("Удалили")
    });
  }
}
