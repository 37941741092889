import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Device, Format, Participant} from 'src/app/domain/models';
import {FractalService} from 'src/app/services/fractal/fractal.service';
import {NotificationService} from "../../services/notifications/notification.service";

@Component({
  selector: 'app-participant-details',
  templateUrl: './participant-details.component.html',
  styleUrls: ['./participant-details.component.scss']
})
export class ParticipantDetailsComponent implements OnInit {

  model: Participant;
  event: Format;
  devices: Device[];
  isUpdateEventEnabled = false;
  isCreateFormatEnabled = false;
  eventId: string = "";
  dayId: string = "";
  formatId: string = "";
  groupId: string = "";
  teamId: string = "";
  participantId: string = "";


  constructor(
    private fractalService: FractalService,
    private route: ActivatedRoute,
    private notifyService : NotificationService
  ) { }

  ngOnInit() {
    const id1: string = this.route.snapshot.params.id1;
    const id2: string = this.route.snapshot.params.id2;
    const id3: string = this.route.snapshot.params.id3;
    const id4: string = this.route.snapshot.params.id4;
    const id5: string = this.route.snapshot.params.id5;
    const id6: string = this.route.snapshot.params.id6;


    this.eventId = id1;
    this.dayId = id2;
    this.formatId = id3;
    this.groupId = id4;
    this.teamId = id5;
    this.participantId = id6;

    this.fractalService.getDevices(this.participantId).subscribe(g => {
      this.devices = g;
    })
    this.fractalService.getParticipant(this.eventId, this.dayId, this.formatId, this.groupId, this.teamId, this.participantId).subscribe(g => {
      this.model = g;
    })
  }


  save() {
    console.log("model: " + this.model);

    this.fractalService.updateParticipant(this.eventId, this.dayId,this.formatId, this.groupId,this.teamId,this.participantId, this.model).subscribe(res => {
      this.notifyService.showSuccess(res);
    });
  }

  showOrHideUpdateDialog() {
    this.isUpdateEventEnabled = !this.isUpdateEventEnabled
  }

  showOrHideCreateDialog() {
    this.isCreateFormatEnabled = !this.isCreateFormatEnabled
    this.fractalService.getDevices(this.participantId).subscribe(g => {
      this.devices = g;
    })
  }
}
