import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DeviceState} from 'src/app/domain/models';
import {FractalService} from 'src/app/services/fractal/fractal.service';
import {NotificationService} from "../../services/notifications/notification.service";

@Component({
  selector: 'app-devices-state',
  templateUrl: './devices-state.component.html'
})
export class DevicesStateComponent implements OnInit {

  data: DeviceState[];

  isLoading: boolean = false;

  eventId: string = "";
  dayId: string = "";
  currentBibSortingIsDesc: boolean = false;

  compareSemanticVersions = (a: string, b: string) => {
    try {
      // 1. Split the strings into their parts.
      const a1 = a?.split('.');
      const b1 = b.split('.');
      // 2. Contingency in case there's a 4th or 5th version
      const len = Math.min(a1.length, b1.length);
      // 3. Look through each version number and compare.
      for (let i = 0; i < len; i++) {
        const a2 = +a1[i] || 0;
        const b2 = +b1[i] || 0;

        if (a2 !== b2) {
          return a2 > b2 ? 1 : -1;
        }
      }

      // 4. We hit this if the all checked versions so far are equal
      //
      return b1.length - a1.length;
    } catch (ex) {
      return 1;
    }
  };
  currentLastActiveTimeSortingIsDesc: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: FractalService,
    private notifyService: NotificationService
  ) {
    const eventId: string = this.route.snapshot.params.id1;
    this.eventId = eventId;
    const dayId: string = this.route.snapshot.params.id2;
    this.dayId = dayId;
  }

  ngOnInit(): void {
    this.api.getDevicesState(this.route.snapshot.params.id1, this.route.snapshot.params.id2).subscribe((res) => {
      this.data = res
      this.data.forEach(x => {
      })
      this.isLoading = true
    })
  }

  openParticipantDetails(team: DeviceState) {
    // this.router.navigateByUrl("/events/" + this.eventId + "/days/" + this.dayId + "/participants/" + team.participantId)
  }

  openDeviceDetails(team) {

  }

  openGroupDetails(team: DeviceState) {
    // this.router.navigateByUrl("/events/" + this.eventId + "/days/" + this.dayId + "/groups/" + team.participantGroupId)
  }

  openTeamDetails(team: DeviceState) {
    // this.router.navigateByUrl("/events/" + this.eventId + "/days/" + this.dayId + "/teams/" + team.participantTeamId, { state: { groupId: team.participantGroupId } })
  }

  sortByAppVersion() {
    this.data.sort((a, b) => a?.device?.deviceInfo?.appVersion.replace(/\d+/g, n => n + 100000)
      .localeCompare(b?.device?.deviceInfo?.appVersion.replace(/\d+/g, n => n + 100000)));
    // this.data = this.data.sort((a, b) => {
    // return this.compareSemanticVersions(a?.device?.deviceInfo?.appVersion, b?.device?.deviceInfo?.appVersion)
    // })
  }

  sortByBib() {
    console.log("SORT")
    this.currentBibSortingIsDesc = !this.currentBibSortingIsDesc
    this.data.sort((a, b) => {
      if (a?.participant?.bib > b?.participant?.bib)
        if (this.currentBibSortingIsDesc) {
          return 1
        } else {
          return -1
        }
      if (a?.participant?.bib < b?.participant?.bib)
        if (this.currentBibSortingIsDesc) {
          return -1
        } else {
          return 1
        }
      return 0;
    })
  }

  sortByLastActiveTime() {
    this.currentLastActiveTimeSortingIsDesc = !this.currentLastActiveTimeSortingIsDesc
    this.data.sort((a, b) => {
      if (new Date(a?.device?.lastActiveTime).getTime() > new Date(b?.device?.lastActiveTime).getTime())
        if (this.currentLastActiveTimeSortingIsDesc) {
          return 1
        } else {
          return -1
        }
      if (new Date(a?.device?.lastActiveTime).getTime() < new Date(b?.device?.lastActiveTime).getTime())
        if (this.currentLastActiveTimeSortingIsDesc) {
          return -1
        } else {
          return 1
        }
      return 0;
    })
  }

  navigateToDetails(deviceId) {
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/devices/' + deviceId], {
    //   queryParams: {
    //     deviceId: deviceId,
    //     cpId: this.data.find(x => x.device.id == deviceId).participant?.distances?.[0].controlPointSettingsId
    //   }
    // }));
    // window.open(url, '_blank');
    this.router.navigate(['/devices/' + deviceId, {
      deviceId: deviceId,
      cpId: this.data.find(x => x.device.id == deviceId).participant?.distances?.[0].controlPointSettingsId
    }])
  }
}
