import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FractalService} from "../../services/fractal/fractal.service";
import {Title} from "@angular/platform-browser";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NotificationService} from "../../services/notifications/notification.service";
import {PreferencesService} from "../../services/preferences/preferences.service";
import {EventResultsTableComponent} from "../event-results-table/event-results-table.component";
import {analyzeEventInfo, getSortedGroups, parseResults, sortResults, sortTeams2} from "../../domain/data-utls";
import {EventDay, EventInfo} from "../../domain/models";

@Component({
  selector: 'app-event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.scss']
})
export class EventInfoComponent {

  data: EventInfo;
  sortedGroups: string[];
  currentEvent: EventDay;
  isLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private api: FractalService,
    private titleService: Title,
    private modalService: NgbModal,
    private notifyService: NotificationService,
    private preferencesService: PreferencesService,
  ) {
    console.log(preferencesService.appPreferences)
    titleService?.setTitle('Статистика');
  }

  ngOnInit(): void {
    this.api.getResultsNew(this.route.snapshot.params.id1, this.route.snapshot.params.id2).subscribe((res) => {
      EventResultsTableComponent.cpSettings = res.controlPointsSettings
      let newResult = parseResults(res)
      newResult = sortResults(newResult)
      this.sortedGroups = getSortedGroups(newResult)
      newResult = sortTeams2(newResult, "rogaine")

      this.data = analyzeEventInfo(newResult.participants); //TODO convert list to analyzed points
      this.isLoading = false
    })
    this.api.GetDay(this.route.snapshot.params.id, this.route.snapshot.params.id2).subscribe((res) => {
      this.currentEvent = res;
    })
  }
}
