<div class="content">
  <h2>Привязка/Отвязка</h2>

  <div>
    <h3>ID команды</h3>
    <input type="text" class="form__field" id="major" [(ngModel)]="modelGroup.teamId" placeholder="" name="oldGroupId"
      #name="ngModel">
  </div>

  <div>
    <h3>Группа</h3>
    <select style="margin: 20px;" class="form-control" id="group"
      [(ngModel)]="modelGroup.oldGroupId"
      name="currentGroup" ng-model="currentGroup" ng-options="x for x in groups">
      <option *ngFor="let c of teamsAndGroups?.groups" [ngValue]="c?.id">{{c?.name}}</option>
    </select>
  </div>

  <div>
    <button type="button" class="button-black" (click)="link()">Привязать</button>
    <button type="button" class="button-black" (click)="unlink()">Отвязать</button>
  </div>
</div>
