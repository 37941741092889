<!--<div class="navigation-bar">-->
<!--  <nav>-->
<!--    <a routerLink="/events" class="nav-item">Мероприятия</a>-->
<!--  </nav>-->
<!--  <nav>-->
<!--    <a routerLink="/settings" class="nav-item">Настройки</a>-->
<!--  </nav>-->
<!--</div>-->
<!--<div>-->
<!--  <router-outlet></router-outlet>-->
<!--</div>-->


<div class="admin-layout" [ngStyle]="{
  'grid-template-columns':showLeftNav?'20% 80%':'5% 95%'
}">
  <!-- for left nav -->
  <div class="left-nav">

    <!-- for brand name -->
    <div class="brand" routerLink="" [hidden]="!showLeftNav" (click)="restoreMenu()">
      <h2 [ngStyle]="{
        'font-size':showLeftNav?'30px':'10px'
      }">
        Fractal Admin
      </h2>
    </div>

    <ul class="menu">
      <li *ngFor="let menuItem of menuItems" [class.has-children]="menuItem.childrens && menuItem.childrens.length">
        <a [routerLink]="menuItem.link" routerLinkActive="active" (click)="expand(menuItem)">{{ menuItem.name }}</a>
        <ul *ngIf="menuItem.childrens && menuItem.childrens.length" class="submenu" [hidden]="!menuItem.expanded">
          <li *ngFor="let childItem of menuItem.childrens">
            <a [routerLink]="childItem.link" routerLinkActive="active">{{ childItem.name }}</a>
          </li>
        </ul>
      </li>
    </ul>
<!--    <ol>-->
<!--      <li *ngFor="let item of menuItems;let i=index;" (click)="handleClick(item)" >-->
<!--&lt;!&ndash;        [routerLink]="item.url" routerLinkActive="active-link"&ndash;&gt;-->
<!--        <div class="left-nav-list-item">-->
<!--          <fa-icon [ngStyle]="{'margin-left':showLeftNav?'':'10px'}" [icon]="item.icon"></fa-icon>-->
<!--          <span *ngIf="showLeftNav" [hidden]="!showLeftNav">-->
<!--            {{-->
<!--            item.title-->
<!--            }}-->
<!--          </span>-->
<!--          <div class="left-nav-list-item" *ngFor="let item2 of item.childrens;let i=index;">-->
<!--            <fa-icon [ngStyle]="{'margin-left':showLeftNav?'':'10px'}" [icon]="item2.icon"></fa-icon>-->
<!--          </div>-->
<!--        </div>-->
<!--      </li>-->
<!--    </ol>-->

  </div>

  <!-- for content -->
  <div class="admin-content">

    <div class="main-content-nav">
<!--      <button style="background: transparent" (click)="showAndHideLeftNav()">-->
<!--        <fa-icon  style="color: white" [icon]="faBars"></fa-icon>-->
<!--      </button>-->

      <div>
        <h1></h1>
      </div>

      <div class="user-info">
<!--        <fa-icon [icon]="userIcon"></fa-icon>-->
<!--        <b>inohunk</b>-->
      </div>
    </div>


    <div class="content-outlet">

      <router-outlet>

      </router-outlet>

    </div>
  </div>
</div>
