import {IconDefinition} from "@fortawesome/free-solid-svg-icons";

export class MenuItem {
  id: string;
  name: string;
  link: string;
  icon: IconDefinition;
  childrens: Array<MenuItem>;
  expanded: boolean = true;

  constructor(
    id: string,
    name: string,
    link: string,
    icon: IconDefinition,
    childrens: Array<MenuItem>,
    expanded: boolean = false,
  ) {
    this.id = id
    this.name = name
    this.link = link
    this.icon = icon
    this.expanded = expanded
    this.childrens = childrens
  }
}
