import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ViewportScroller} from '@angular/common';
import {
  ControlPointsSettings,
  EventDay,
  ParticipantInfo,
  ParticipantStatuses,
  ParticipantWithDeviceDto,
} from 'src/app/domain/models';
import {getFinishTime, getFinishTimeInMillis, getPenalty, getStartTime, scoreNew} from 'src/app/domain/result-utils';
import {Title} from "@angular/platform-browser";
import {FractalService} from "../../services/fractal/fractal.service";
import {millisToTime, utcToLocal} from "../../domain/date-utils";
import {getParticipantStatus, getSortedGroups, parseResults, sortResults, sortTeams2} from "../../domain/data-utls";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {PDFDocument, rgb, StandardFonts} from 'pdf-lib';

@Component({
  selector: 'app-event-results-table',
  templateUrl: './event-results-table.component.html'
})
export class EventResultsTableComponent implements OnInit {
  static cpSettings: ControlPointsSettings[] = [];
  static selectedSettings = ""
  data: ParticipantInfo[]; //TODO sort groups
  sortedGroups: string[];
  currentEvent: EventDay;
  currentTime = moment.utc(Date.now()).local().format("MM-DD-YYYY HH:mm:ss");

  isLoading: boolean = true;

  score(device, controlPointSettingsId: string): string {
    if (device == null) return "0"
    return scoreNew(device).toString()
  }

  navigateToDevice(participant) {
    EventResultsTableComponent.selectedSettings = participant.controlPointSettingsId
    this.router.navigateByUrl("/devices/" + participant.device?.id, {state: {cpSettingsId: participant?.controlPointSettingsId}})
  }

  constructor(
    private actRoute: ActivatedRoute,
    private api: FractalService,
    private modalService: NgbModal,
    private scroller: ViewportScroller,
    private router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle("Результаты");
  }

  scroll(id: string) {
    this.scroller.scrollToAnchor(id);
  }

  getStartTime(participant: ParticipantWithDeviceDto) {
    try {
      if (participant == null) return "-";
      if (participant?.device == null) return utcToLocal(participant.controlStartTime);
      return getStartTime(participant);
    } catch (error) {
      return "-";
    }
  }

  getFinishTimeFromParticipant(participant: ParticipantWithDeviceDto) {
    return millisToTime(getFinishTimeInMillis(participant))
  }

  getDifferenceBetweenTimes(participant: ParticipantWithDeviceDto) {
    let difference;
    let foundStart;
    let foundFinish;
    try {
      foundFinish = (participant.newDevice).punches.find(e => e.code == 240).timestamp;
      foundStart = (participant.newDevice).punches?.find(e => e.code == 241)?.timestamp;
      if (foundStart == null) {
        foundStart = participant.controlStartTime
      }
      difference = new Date(foundFinish).valueOf() - new Date(foundStart).valueOf();
      return moment.utc(new Date(difference)).utc().format("HH:mm:ss");
    } catch (error) {
      try {
        foundFinish = (participant.newDevice).punches.find(e => e.code == 240).timestamp;
        foundStart = participant.controlStartTime;
        difference = new Date(foundFinish).valueOf() - new Date(foundStart).valueOf();
        return difference
      } catch (error) {
        return "-";
      }
    }
  }

  getPenalty(participant: ParticipantWithDeviceDto) {
    try {
      if (participant?.newDevice == null || participant?.controlFinishTime == "" || participant?.controlFinishTime == "-") return "-";
      const startTime = participant?.controlFinishTime;
      const finishTime = getFinishTimeInMillis(participant);
      if (finishTime == null || startTime == null || startTime == "" || finishTime === -1) return "-"
      const penalty = getPenalty(startTime, finishTime.toString());
      if (penalty == null) return "-"; else return penalty
    } catch (error) {
      return "-";
    }
  }

  getResult(participant: ParticipantWithDeviceDto) {
    try {
      const score = this.score(participant.newDevice, participant.controlPointsSettingsId);
      let penalty = this.getPenalty(participant);
      if (penalty == "-") {
        penalty = 0
      }
      return +score - +penalty;
    } catch (error) {
      return "-";
    }
  }

  getStatus(participant: ParticipantWithDeviceDto) {
    if (participant?.device?.id === null) return "DNS"
    if (getFinishTime(participant) === "-") return "RUN"
    if (getFinishTime(participant) != "-") return "FINISHED"
    return "Unknown"
  }

  ngOnInit(): void {
    this.api.getResultsNew(this.actRoute.snapshot.params.id1, this.actRoute.snapshot.params.id2).subscribe((res) => {
      EventResultsTableComponent.cpSettings = res.controlPointsSettings
      let newResult = parseResults(res)
      var max = ""
      res.teams.forEach(x => {
        x.runners.forEach(r => {
          if (max < r.device?.lastActiveTime) max = r.device?.lastActiveTime
        })
      })
      newResult = sortResults(newResult)
      this.sortedGroups = getSortedGroups(newResult)
      newResult = sortTeams2(newResult, this.currentEvent?.type)
      this.data = newResult.participants;
      this.isLoading = false
    })
    this.api.GetDay(this.actRoute.snapshot.params.id, this.actRoute.snapshot.params.id2).subscribe((res) => {
      this.currentEvent = res;
    })
  }

  getParticipantStatus(participant: ParticipantWithDeviceDto): string {
    const status = getParticipantStatus(participant)
    switch (status) {
      case ParticipantStatuses.NOT_REGISTERED: {
        return "Не зарегистрирован(-a)"
      }
      case ParticipantStatuses.STARTED: {
        return "Стартовал(-a)"
      }
      case ParticipantStatuses.FINISHED: {
        return "Финишировал(-a)"
      }
      case ParticipantStatuses.NOT_STARTED: {
        return "Не стартовал(-a)"
      }
    }
    return ""
  }

  async modifyPdf(participant: ParticipantWithDeviceDto[], place: number, group: string) {
    const url = this.currentEvent.diploma.url
    const existingPdfBytes = await fetch(url).then(res =>
      res.arrayBuffer()
    )

    console.log(existingPdfBytes)

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.TimesRomanItalic)
    const helveticaSize = 24;
    const helveticaSize2 = 16;
    const helvetica2Font = await pdfDoc.embedFont(StandardFonts.TimesRoman)
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    const {x, y, width, height} = firstPage.getArtBox()
    let str = '';
    [1,1,1]?.forEach(x => {
      // str += x.name + " " + x.surname + "\n"
      // str += "Test1 Test1" + "\n"
    });
    const secondaryText = place + " place of group " + "Test";
    // const secondaryText = "Za " + place + " place of group " + group;
    console.log(x)
    console.log(y)
    console.log(width)
    console.log(height)
    const textWidth1 = helveticaFont.widthOfTextAtSize(secondaryText, helveticaSize)
    const textHeight1 = helveticaFont.heightAtSize(helveticaSize)
    const textHeight2 = helvetica2Font.heightAtSize(helveticaSize2)
    firstPage.drawText(secondaryText, {
      x: width / 2 - textWidth1 / 2,
      y: height / 3 - textHeight1 / 2,
      size: helveticaSize,
      font: helveticaFont,
      color: rgb(0, 0.1, 0.1),
    })

    for (let i = 0; i < 3; i++) {
      const text = "QEFWEFWEFEW EWFEWFEWEFFEWТЕСТ" + i
      const textWidth2 = helvetica2Font.widthOfTextAtSize(text, helveticaSize2)
      firstPage.drawText(text, {
        x: (width / 2) - textWidth2 / 2,
        y: height / 3 - textHeight2 / 2 - 30 - i * 20,
        size: helveticaSize2,
        font: helvetica2Font,
        color: rgb(0, 0, 0),
      })
    }

    const pdfBytes = await pdfDoc.saveAsBase64()
    this.downloadPDF(pdfBytes)
    // File(pdfBytes, "application/pdf");
  }

  generatePdf(participant: ParticipantWithDeviceDto[], place: number, group: string) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    let str = '';
    participant?.forEach(x => {
      str += x.name + " " + x.surname + "\n"
    });
    const secondaryText = "За " + place + " место по группе " + group;
    const documentDefinition = {
      pageSize: 'A4',
      pageMargins: [120, 550, 120, 60],
      content: [
        {
          text: secondaryText,
          style: 'secondaryText'
        },
        {
          text: str,
          style: "base"
        }
      ],
      styles: {
        base: {
          alignment: 'center',
          bold: false,
          fontSize: 18,
        },
        secondaryText: {
          fontSize: 22,
          italics: true,
          alignment: 'center'
        }
      }
    };

    pdfMake.createPdf(documentDefinition).download();
  }

  downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "abc.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
}
