<div class="container">
  <h1>Изменение участника</h1>
  <div>
    <button class="button" (click)="showOrHideUpdateDialog()">Открыть диалог с обновлением данных</button>
  </div>
  <form #heroForm="ngForm" [hidden]="!isUpdateEventEnabled">
    <div class="form-group">
      <label for="bib">BIB</label>
      <input type="text" class="form-control" id="bib" required [(ngModel)]="model.bib" name="bib" #name="ngModel">
      <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
        Обязательное поле
      </div>
    </div>

    <div class="form-group">
      <label for="name">Имя</label>
      <input type="text" class="form-control" id="name" required [(ngModel)]="model.name" name="name" #name="ngModel">
      <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
        Обязательное поле
      </div>
    </div>
    <div class="form-group">
      <label for="surname">Фамилия</label>
      <input type="text" class="form-control" id="surname" required [(ngModel)]="model.surname" name="surname" #name="ngModel">
      <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
        Обязательное поле
      </div>
    </div>

    <div class="form-group">
      <label for="birthDate">Дата рождения</label>
      <input type="text" class="form-control" id="birthDate" required [(ngModel)]="model.birthDate" name="birthDate" #name="ngModel">
      <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
        Обязательное поле
      </div>
    </div>

    <div class="form-group">
      <label for="gender">Пол</label>
      <input type="text" class="form-control" id="gender" required [(ngModel)]="model.gender" name="gender" #name="ngModel">
      <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
        Обязательное поле
      </div>
    </div>
    <div class="form-group">
      <label for="gender">Контрольное время старта</label>
      <input type="text" class="form-control" id="controlStartTime" required [(ngModel)]="model.controlStartTime" name="controlStartTime" #name="ngModel">
    </div>
    <div class="form-group">
      <label for="gender">Контрольное время финиша</label>
      <input type="text" class="form-control" id="controlFinishTime" required [(ngModel)]="model.controlFinishTime" name="controlFinishTime" #name="ngModel">
    </div>
    <button type="button" class="button-main" (click)="save();">Сохранить</button>
  </form>

  <h1>Устройства</h1>
  <div class="heroes">
    <li *ngFor="let device of devices">
      <a routerLink="/devices/{{device.id}}">
        <span>id: {{device.id}}</span>
        <div>
          <p>imaginaryId: {{device.imaginaryId}}</p>
          <p>manufacturer: {{device.deviceInfo.manufacturer}}</p>
          <p>model: {{device.deviceInfo.model}}</p>
          <p>systemVersion: {{device.deviceInfo.systemVersion}}</p>
          <p>appVersion: {{device.deviceInfo.appVersion}}</p>
          <p>createdDate: {{device.createdDate}}</p>
          <p>lastActiveTime: {{device.lastActiveTime}}</p>
          <button routerLink="devices/{{device.id}}/punches">Punches</button>
          <button routerLink="devices/{{device.id}}/state">Battery state</button>
        </div>
      </a>
  </div>
</div>
