import {NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSliderModule} from '@angular/material/slider';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCommonModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {SettingsComponent} from './pages/settings/settings.component';
import {GpsSettingsComponent} from './pages/gps-settings/gps-settings.component';
import {PunchAlgorithmComponent} from './pages/punch-algorithm/punch-algorithm.component';
import {FormatDetailsComponent} from './components/format-details/format-details.component';
import {GroupDetailsComponent} from './components/group-details/group-details.component';
import {TeamDetailsComponent} from './components/team-details/team-details.component';
import {ParticipantDetailsComponent} from './components/participant-details/participant-details.component';
import {DevicesStateComponent} from './components/devices-state/devices-state.component';
import {EventsComponent} from './pages/events/events.component';
import {EventDaysComponent} from './pages/event-days/event-days.component';
import {DayDetailsComponent} from './pages/day-details/day-details.component';
import {ControlPointsSettingsComponent} from './pages/control-points-settings/control-points-settings.component';
import {ParticipantsManageComponent} from './pages/participants-manage/participants-manage.component';
import {DevicesManageComponent} from './pages/devices-manage/devices-manage.component';
import {ManageLinksComponent} from './pages/manage-links/manage-links.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpInterceptorService} from "./services/interceptors/HttpInterceptorService";
import {ToastrModule} from 'ngx-toastr';
import {EventResultsTableComponent} from "./pages/event-results-table/event-results-table.component";
import {DeviceInfoComponent} from './pages/device-info/device-info.component';
import {GroupInfoComponent} from './pages/group-info/group-info.component';
import {TeamInfoComponent} from './pages/team-info/team-info.component';
import {ParticipantInfoComponent} from './pages/participant-info/participant-info.component';
import {FilterListPipe} from './helpers/pipes/filter-list.pipe';
import {FilterGroupsPipe} from './helpers/pipes/filter-groups/filter-groups.pipe';
import {SortDirective} from "./helpers/sort/sort.directive";
import {AnalyzePointsComponent} from './pages/analyze-points/analyze-points.component';
import {EditControlPointsComponent} from "./pages/edit-control-points/edit-control-points.component";
import {EventInfoComponent} from './pages/event-info/event-info.component';
import {AnalyzeGpsTracksComponent} from "./pages/analyze-gps-tracks/analyze-gps-tracks.component";
import {SignInComponent} from './pages/auth/sign-in/sign-in.component';
import {EventsManageComponent} from './pages/events-manage/events-manage.component';
import {FaIconComponent, FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {MatGridListModule} from "@angular/material/grid-list";
import {ClipboardModule} from "@angular/cdk/clipboard";

@NgModule({
  declarations: [
    AppComponent,
    EventsComponent,
    PageNotFoundComponent,
    SettingsComponent,
    GpsSettingsComponent,
    PunchAlgorithmComponent,
    FormatDetailsComponent,
    GroupDetailsComponent,
    TeamDetailsComponent,
    ParticipantDetailsComponent,
    EventDaysComponent,
    DevicesStateComponent,
    DayDetailsComponent,
    ControlPointsSettingsComponent,
    ParticipantsManageComponent,
    DevicesManageComponent,
    ManageLinksComponent,
    EventResultsTableComponent,
    DeviceInfoComponent,
    GroupInfoComponent,
    TeamInfoComponent,
    FilterListPipe,
    ParticipantInfoComponent,
    FilterListPipe,
    FilterGroupsPipe,
    SortDirective,
    AnalyzePointsComponent,
    EditControlPointsComponent,
    AnalyzeGpsTracksComponent,
    EventInfoComponent,
    SignInComponent,
    EventsManageComponent,
  ],
  imports: [
    BrowserModule,
    MatSliderModule,
    MatSidenavModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    FontAwesomeModule,
    MatSliderModule,
    MatGridListModule,
    MatCommonModule,
    MatFormFieldModule,
    ClipboardModule,
    ReactiveFormsModule,
    NgbModule,
    FormsModule,
    ToastrModule.forRoot(
      {
        timeOut: 2500,
        positionClass: 'toast-top-right'
      }
    ),
    FaIconComponent,
    MatGridListModule
  ],
  providers: [
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    // {
    //   provide: LocationStrategy,
    //   useClass: HashLocationStrategy
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
