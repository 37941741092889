import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Format, Group, Participant, Team, UpdateLinks} from 'src/app/domain/models';
import {FractalService} from 'src/app/services/fractal/fractal.service';
import {NotificationService} from "../../services/notifications/notification.service";

@Component({
  selector: 'app-team-details',
  templateUrl: './team-details.component.html',
  styleUrls: ['./team-details.component.scss']
})
export class TeamDetailsComponent implements OnInit {

  model: Team;
  event: Format;
  groups: Group[];
  currentGroup: Group;
  participants: Participant[];
  isUpdateEventEnabled = false;
  isCreateFormatEnabled = false;
  eventId: string = "";
  dayId: string = "";
  formatId: string = "";
  groupId: string = "";
  teamId: string = "";


  constructor(
    private fractalService: FractalService,
    private route: ActivatedRoute,
    private router: Router,
    private notifyService : NotificationService
  ) { }

  ngOnInit() {
    const id1: string = this.route.snapshot.params.id1;
    const id2: string = this.route.snapshot.params.id2;
    const id3: string = this.route.snapshot.params.id3;
    const id4: string = this.route.snapshot.params.id4;
    const id5: string = this.route.snapshot.params.id5;

    this.eventId = id1;
    this.dayId = id2;
    this.formatId = id3;
    this.groupId = id4;
    this.teamId = id5;

    this.fractalService.getParticipants(this.eventId, this.dayId, this.formatId, this.groupId, this.teamId).subscribe(g => {
      this.participants = g;
    })
    this.fractalService.getTeam(this.eventId, this.dayId, this.formatId, this.groupId, this.teamId).subscribe(g => {
      this.model = g;
    })

    this.fractalService.getGroups(this.eventId, this.dayId, this.formatId).subscribe(g => {
      this.groups = g;
    })
  }


  save() {
    console.log("model: " + this.model);

    this.fractalService.updateTeam(this.eventId, this.dayId, this.formatId, this.groupId, this.teamId, this.model).subscribe(res => {
      console.log("res: " + res);
    });
    let updateLinks = new UpdateLinks(this.teamId,this.currentGroup.id);
    updateLinks.entityId = this.groupId;
    updateLinks.newId = this.currentGroup.id.toString();
    console.log(this.currentGroup);
    console.log(updateLinks);

    this.fractalService.linkTeamToOtherGroup(this.eventId, this.dayId, this.teamId, updateLinks)
  }

  showOrHideUpdateDialog() {

    this.isUpdateEventEnabled = !this.isUpdateEventEnabled
  }

  showOrHideCreateDialog() {
    this.isCreateFormatEnabled = !this.isCreateFormatEnabled
    this.fractalService.getParticipants(this.eventId, this.dayId, this.formatId, this.groupId, this.teamId).subscribe(g => {
      this.participants = g;
    })
  }

}
