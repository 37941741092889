<div class="container">
  <div class="div-default">
    <button class="button-main" (click)="open(content2)">Открыть диалог с обновлением данных</button>
  </div>
  <div class="div-default">
    <button class="button-main" (click)="openCreateDialog(content)">Создать формат</button>
  </div>

  <div class="div-default">
    <button class="button-main" (click)="openAddParticipantDialog(contentAddParticipant)">Загрузить CSV
    </button>
  </div>

  <div class="div-default">
    <button class="button-main" (click)="openAddParticipantsDialog(contentAddParticipants)">Добавление участников
    </button>
  </div>

  <div class="div-default">
    <a class="button-main" routerLink="state" target="_blank">Устройства</a>
  </div>

  <div class="div-default">
    <a class="button-main" routerLink="results" target="_blank">Результаты</a>
  </div>

  <div class="div-default">
    <a class="button-main" routerLink="analyze" target="_blank">Анализ КП</a>
  </div>

  <div class="div-default">
    <a class="button-main" routerLink="info" target="_blank">Статистика</a>
  </div>

  <div class="div-default">
    <a class="button-main" routerLink="analyze-gps" target="_blank">Анализ треков</a>
  </div>

  <!--  <a class="button" routerLink="manage">Изменение команд/групп</a>-->
  <!--  <a class="button" routerLink="manage-all">Управление участниками</a>-->
  <!--  <a class="button" routerLink="manage-links">Привязка/Отвязка от групп</a>-->
  <!--  <a class="button" routerLink="devices-manage">Управление устройствами</a>-->
</div>

<div class="div-center">
  <h1 class="title-base">Форматы</h1>
</div>
<!-- <div class="div-center list">
  <li *ngFor="let format of formats">
    <a>
      <span class="title">{{format.name}}</span>
      <div>
        <p class="text">{{format.punchAlgorithm.name}}</p>
        <select class="form-control" id="punch-algorithm" [(ngModel)]="format.punchAlgorithm"
                [value]="format.punchAlgorithm" name="punch-algorithm" ng-model="format.punchAlgorithm">
          <option *ngFor="let c of punchAlgorithms" [ngValue]="c">{{c.name}}</option>
        </select>
        <p class="text">{{format.gpsSettings.name}}</p>
        <select class="form-control" id="gps-settings" [(ngModel)]="format.gpsSettings" [value]="format.gpsSettings"
                name="gps-settings" ng-model="format.gpsSettings" ng-options="x for x in gpsSettings">
          <option *ngFor="let c of gpsSettings" [ngValue]="c">{{c.name}}</option>
        </select>

        <div class="div-center">
          <button class="button-black" routerLink="formats/{{format.id}}">Детали</button>
        </div>
      </div>
    </a>
</div> -->

<div style="margin: 20px;">
  <table class="table">
    <thead>
    <tr>
      <th>id</th>
      <th>title</th>
      <th>gps</th>
      <th>punch</th>
      <th>actions</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let format of formats" class="div-center">
      <td>
        <div class="device-details">
          <p class="p-base">{{format.id}}</p>
        </div>
      </td>
      <td>
        <div class="device-details">
          <p class="p-base">{{format.name}}</p>
        </div>
      </td>
      <td>
        <div class="device-details">
          <p class="p-base">{{format.gpsSettings.name}} ({{format.gpsSettings.id}})</p>
        </div>
      </td>
      <td>
        <div class="device-details">
          <p class="p-base">{{format.punchAlgorithm.name}} ({{format.punchAlgorithm.id}})</p>
        </div>
      </td>
      <td>
        <a class="button-black" routerLink="formats/{{format.id}}" target="_blank">Детали</a>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<ng-template #content2 let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      Обновление этапа
    </h1>

    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <label for="name">Название</label>
      <input type="text" class="form-control" id="name" required [(ngModel)]="model.name" name="name"
             placeholder="Яркая весна" #name="ngModel">
      <div class="form-group">
        <label for="description">Описание</label>
        <input type="text" class="form-control" id="description" [(ngModel)]="model.description"
               placeholder="Мероприятие для самых активных" name="description">
      </div>

      <div class="form-group">
        <label for="beginDate">Дата начала</label>
        <input type="text" class="form-control" id="beginDate" [(ngModel)]="model.beginDate" name="beginDate"
               placeholder="2021-07-10T14:02:33Z" #name="ngModel">
      </div>

      <div class="form-group">
        <label for="endDate">Дата окончания</label>
        <input type="text" class="form-control" id="endDate" [(ngModel)]="model.endDate" name="endDate"
               placeholder="2021-07-10T14:02:33Z">
      </div>

      <div class="form-group">
        <label for="ogpsEventId">OGPS ID</label>
        <input type="text" class="form-control" id="ogpsEventId" [(ngModel)]="model.ogpsEventId" name="ogpsId"
               placeholder="5555">
      </div>

      <div class="form-group">
        <label for="ogpsEventUrl">OGPS URL</label>
        <input type="text" class="form-control" id="ogpsEventUrl" [(ngModel)]="model.ogpsEventUrl" name="ogpsEventUrl"
               placeholder="https://viewer.o-gps-center.ru/viewer/event/10377/">
      </div>

      <div class="form-group" style="display: block;">
        <label for="registrationBeginDate">Дата начала регистрации</label>
        <input type="text" class="form-control" id="registrationBeginDate" placeholder="2021-07-10T14:02:33Z"
               [(ngModel)]="model.registrationBeginDate" name="registrationBeginDate">
      </div>

      <div class="form-group form-check">
        <div>
          <input type="checkbox" class="form-check-input" id="published" [(ngModel)]="model.published" name="published">
          <label class="form-check-label" for="published">Мероприятие опубликовано</label>
        </div>
      </div>

      <div class="form-group form-check">
        <div>
          <input type="checkbox" class="form-check-input" id="closed" [(ngModel)]="model.closed" name="closed">
          <label class="form-check-label" for="closed">Мероприятие закрыто</label>
        </div>
      </div>

      <div class="form-group form-check">
        <div>
          <input type="checkbox" class="form-check-input" id="closed" [(ngModel)]="model.qrEnabled" name="closed">
          <label class="form-check-label" for="closed">QR коды включены</label>
        </div>
      </div>

      <div class="form-group" style="display: block;">
        <label for="registrationBeginDate">Ссылка на карту</label>
        <input type="text" class="form-control" id="mapUrl" placeholder=""
               [(ngModel)]="model.mapUrl" name="mapUrl">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
      Save
    </button>
  </div>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      Создание формата
    </h1>

    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="name">Название</label>
        <input type="text" class="form-control" id="name" [(ngModel)]="newModel.name" name="name" placeholder="5555">
      </div>
      <div class="form-group">
        <input type="checkbox" class="form-check-input" id="published" [(ngModel)]="newModel.autoRegisterInGroups"
               name="published">
        <label class="form-check-label" for="autoRegisterInGroups">Автоматическая регистрация в группы</label>
      </div>

      <div class="form-group">
        <label for="punch-algorithm">Алгоритм отметки</label>
        <select class="form-control" id="punch-algorithm" [(ngModel)]="selectedAlgorithm" name="punch-algorithm"
                ng-model="selectedAlgorithm" ng-options="x for x in punchAlgorithms">
          <option *ngFor="let c of punchAlgorithms" [ngValue]="c">{{c.name}}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="gps-settings">Gps настройки</label>
        <select class="form-control" id="gps-settings" [(ngModel)]="selectedGpsSettings" name="gps-settings"
                ng-model="selectedGpsSettings" ng-options="x for x in gpsSettings">
          <option *ngFor="let c of gpsSettings" [ngValue]="c">{{c.name}}</option>
        </select>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
      Save
    </button>
  </div>
</ng-template>

<ng-template #contentAddParticipant let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      Добавление участников
    </h1>
    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="punch-algorithm">Контрольные точки</label>
        <select class="form-control" id="control-points" [(ngModel)]="selectedControlPointsSettings"
                name="control-points"
                ng-model="selectedControlPointsSettings" ng-options="x for x in controlPointsSettings">
          <option *ngFor="let c of controlPointsSettings" [ngValue]="c">{{c.name}}</option>
        </select>
      </div>
      <!--      <div class="form-group">-->
      <!--        <label for="controlPointsSettingsId">ID контрольных точек</label>-->
      <!--        <input type="text" class="form-control" id="controlPointsSettingsId"-->
      <!--               [(ngModel)]="uploadCsvModel.controlPointsSettingsId"-->
      <!--               placeholder="" name="controlPointsSettingsId">-->
      <!--      </div>-->

      <div class="form-group">
        <label for="punch-algorithm">Алгоритм отметки</label>
        <select class="form-control" id="punch-algorithm" [(ngModel)]="selectedAlgorithm" name="punch-algorithm"
                ng-model="selectedAlgorithm" ng-options="x for x in controlPointsSettings">
          <option *ngFor="let c of punchAlgorithms" [ngValue]="c">{{c.name}} - {{c.analyzeDelay}} мс</option>
        </select>
      </div>
      <!--      <div class="form-group">-->
      <!--        <label for="punchAlgorithmId">ID алгоритма отметки</label>-->
      <!--        <input type="text" class="form-control" id="punchAlgorithmId" [(ngModel)]="uploadCsvModel.punchAlgorithmId"-->
      <!--               placeholder="" name="punchAlgorithmId">-->
      <!--      </div>-->

      <div class="form-group">
        <label for="punch-algorithm">GPS настройки</label>
        <select class="form-control" id="gps-settings" [(ngModel)]="selectedGpsSettings" name="gps-settings"
                ng-model="selectedGpsSettings" ng-options="x for x in gpsSettings">
          <option *ngFor="let c of gpsSettings" [ngValue]="c">{{c.name}} - {{c.id}}</option>
        </select>
      </div>
      <!--      <div class="form-group">-->
      <!--        <label for="gpsSettingsId">ID настроек gps</label>-->
      <!--        <input type="text" class="form-control" id="gpsSettingsId" [(ngModel)]="uploadCsvModel.gpsSettingsId"-->
      <!--               placeholder="" name="gpsSettingsId">-->
      <!--      </div>-->

      <div class="form-group">
        <label for="data">Данные</label>
        <textarea type="text" class="form-control" id="data"
                  rows="auto" cols="auto"
                  [(ngModel)]="uploadCsvModel.data"
                  placeholder="" name="data"></textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
      Save
    </button>
  </div>
</ng-template>

<ng-template #contentAddParticipants let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      Добавление участников
    </h1>
    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="punch-algorithm">Контрольные точки</label>
        <select class="form-control" id="control-points" [(ngModel)]="selectedControlPointsSettings"
                name="control-points"
                ng-model="selectedControlPointsSettings" ng-options="x for x in controlPointsSettings">
          <option *ngFor="let c of controlPointsSettings" [ngValue]="c">{{c.name}}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="punch-algorithm">Алгоритм отметки</label>
        <select class="form-control" id="punch-algorithm" [(ngModel)]="selectedAlgorithm" name="punch-algorithm"
                ng-model="selectedAlgorithm" ng-options="x for x in controlPointsSettings">
          <option *ngFor="let c of punchAlgorithms" [ngValue]="c">{{c.name}} - {{c.analyzeDelay}} мс</option>
        </select>
      </div>

      <div class="form-group">
        <label for="punch-algorithm">GPS настройки</label>
        <select class="form-control" id="gps-settings" [(ngModel)]="selectedGpsSettings" name="gps-settings"
                ng-model="selectedGpsSettings" ng-options="x for x in gpsSettings">
          <option *ngFor="let c of gpsSettings" [ngValue]="c">{{c.name}} - {{c.id}}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="data">Номер</label>
        <input type="text" class="form-control" id="bib" [(ngModel)]="newParticipant.bib" name="bib" placeholder="">
        <label for="data">Группа</label>
        <input type="text" class="form-control" id="group" [(ngModel)]="newParticipant.group" name="group" placeholder="">
        <label for="data">Фамилия</label>
        <input type="text" class="form-control" id="name" [(ngModel)]="newParticipant.name" name="name" placeholder="">
        <label for="data">Имя</label>
        <input type="text" class="form-control" id="surname" [(ngModel)]="newParticipant.surname" name="surname"
               placeholder="">
        <label for="data">Команда</label>
        <input type="text" class="form-control" id="teamName" [(ngModel)]="newParticipant.teamName" name="teamName"
               placeholder="">
        <label for="data">Год рождения</label>
        <input type="text" class="form-control" id="birthDate" [(ngModel)]="newParticipant.birthDate" name="birthDate"
               placeholder="">
        <label for="data">Доп группа</label>
        <input type="text" class="form-control" id="childGroup" [(ngModel)]="newParticipant.childGroup" name="childGroup"
               placeholder="">
        <button type="button" class="btn btn-outline-dark" style="margin-top: 16px" (click)="handleAdd()">
          Добавить
        </button>
        <div class="heroes">
          <div *ngFor="let runner of participantsToAdd">
            <p>{{runner.bib}} {{runner.group}} {{runner.name}} {{runner.surname}} {{runner.birthDate}} {{runner.teamName}} {{runner.childGroup}}
                <i id="btn-plus" class="fa fa-close" (click)="handleDelete(runner)"></i>
            </p>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
      Save
    </button>
  </div>
</ng-template>
