import {environment} from "../../../environments/environment";

export class UrlUtils {

  static getProdServer() {
    // return 'http://localhost:8443/api';
    // return `https://fractal.mosplay.ru/api`;
    return environment.apiUrl;
    // return `https://newfractal.mosplay.ru/api`;
    // return `https://.fractal.mosplay.ru`;
    // return `http://fractal.mosplay.ru/api`;
  }

  static getProdGpsServer() {
    // return 'http://localhost:8443/api';
    // return `https://fractal.mosplay.ru/api`;
    return `https://api.fractal.mosplay.ru/api`;
    // return `https://.fractal.mosplay.ru`;
    // return `http://fractal.mosplay.ru/api`;
  }
}
