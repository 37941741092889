import { Component, OnInit } from '@angular/core';
import { AnalyzedPunch, AnalyzedPunchData } from 'src/app/domain/models';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ViewportScroller} from '@angular/common';
import {
  ControlPointsSettings,
  EventDay,
  ParticipantInfo,
  ParticipantStatuses,
  ParticipantWithDeviceDto,
} from 'src/app/domain/models';
import {getFinishTime, getFinishTimeInMillis, getPenalty, getStartTime, scoreNew} from 'src/app/domain/result-utils';
import {Title} from "@angular/platform-browser";
import {FractalService} from "../../services/fractal/fractal.service";
import {millisToTime, utcToLocal} from "../../domain/date-utils";
import {analyzePunches, getParticipantStatus, getSortedGroups, parseResults, sortResults, sortTeams2} from "../../domain/data-utls";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { EventResultsTableComponent } from '../event-results-table/event-results-table.component';

@Component({
  selector: 'app-analyze-points',
  templateUrl: './analyze-points.component.html',
  styleUrls: ['./analyze-points.component.scss']
})
export class AnalyzePointsComponent implements OnInit {

  data: AnalyzedPunchData;
  sortedGroups: string[];
  currentEvent: EventDay;
  isLoading: boolean = true;

  constructor(
    private actRoute: ActivatedRoute,
    private api: FractalService,
    private modalService: NgbModal,
    private scroller: ViewportScroller,
    private router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle("Анализ КП");
  }

  ngOnInit(): void {
    this.api.getResultsNew(this.actRoute.snapshot.params.id1, this.actRoute.snapshot.params.id2).subscribe((res) => {
      EventResultsTableComponent.cpSettings = res.controlPointsSettings
      let newResult = parseResults(res)
      newResult = sortResults(newResult)
      this.sortedGroups = getSortedGroups(newResult)
      newResult = sortTeams2(newResult, "rogaine")
    
      this.data = analyzePunches(newResult.participants); //TODO convert list to analyzed points
      this.isLoading = false
    })
    this.api.GetDay(this.actRoute.snapshot.params.id, this.actRoute.snapshot.params.id2).subscribe((res) => {
      this.currentEvent = res;
    })
  }

}
