<div class="container">
  <h1>Изменение группы</h1>
  <div>
    <button class="button" (click)="showOrHideUpdateDialog()">Открыть диалог с обновлением данных</button>
  </div>
  <form #heroForm="ngForm" [hidden]="!isUpdateEventEnabled">
    <div class="form-group">
      <label for="groupName">Название</label>
      <input type="text" class="form-control" id="groupName" required [(ngModel)]="model.name" name="name" #name="ngModel">
      <div [hidden]="name?.valid || name?.pristine" class="alert alert-danger">
        Обязательное поле
      </div>
    </div>
    <div class="form-group">
      <label for="value">Значение</label>
      <input type="text" class="form-control" id="value" required [(ngModel)]="model.value" name="value" #name="ngModel">
      <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
        Обязательное поле
      </div>
    </div>

    <div class="form-group">
      <label for="startTime">Время старта</label>
      <input type="text" class="form-control" id="startTime" required [(ngModel)]="model.controlStartTime" name="startTime" #name="ngModel">
      <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
        Обязательное поле
      </div>
    </div>

    <div class="form-group">
      <label for="minAge">Минимальный возраст</label>
      <input type="text" class="form-control" id="minAge" required [(ngModel)]="model.minAge" name="minAge" #name="ngModel">
      <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
        Обязательное поле
      </div>
    </div>
    <div class="form-group">
      <label for="maxAge">Максимальный возраст</label>
      <input type="text" class="form-control" id="maxAge" required [(ngModel)]="model.maxAge" name="maxAge" #name="ngModel">
      <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
        Обязательное поле
      </div>
    </div>

    <div class="form-group">
      <label for="controlTime">Контрольное время (В секундах)</label>
      <input type="text" class="form-control" id="controlTime" required [(ngModel)]="controlTime" name="controlTime" #name="ngModel">
      <div [hidden]="name?.valid || name?.pristine" class="alert alert-danger">
        Обязательное поле
      </div>
    </div>
    <div class="form-group">
<!--      <label for="control-points-settings">Настройки контрольных точек ({{model?.distances[0]?.controlPointSettingsId}})</label>-->
      <select class="form-control" id="control-points-settings"
      [(ngModel)]="selectionalControlPointsSettings"
      name="punch-algorithm" ng-model="selectionalControlPointsSettings" ng-options="x for x in controlPointsSettings">
      <option *ngFor="let c of controlPointsSettings" [ngValue]="c">{{c?.name}}</option>
    </select>
    </div>

    <button type="button" class="button-main" (click)="save();">Сохранить</button>
  </form>

  <div>
    <button class="button" (click)="openCreateDialog(content2)">Создать команду</button>
  </div>

  <h1 class="title">Команды</h1>
  <!-- <div class="heroes">
    <li *ngFor="let day of teams">
      <a routerLink="teams/{{day.id}}">
        <span>name: {{day.name}}</span>
        <div>
          <p>id: {{day.id}}</p>
          <p>bib: {{day.bib}}</p>
        </div>
      </a>
  </div> -->
  <div style="margin: 20px;">
    <table class="table">
      <thead>
        <tr>
          <th>id</th>
          <th>name</th>
          <th>bib</th>
          <th>actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let obj of teams" class="div-center">
          <td>
            <div class="device-details">
              <p class="p-base">{{obj.id}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{obj.name}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{obj.bib}}</p>
            </div>
          </td>
          <td>
            <div>
              <a class="button-black" routerLink="teams/{{obj.id}}" target="_blank">Детали</a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #content2 let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      Создание команды
    </h1>

    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="name">Название</label>
        <input type="text" class="form-control" id="name" required [(ngModel)]="newTeamModel.name" name="name" #name="ngModel">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Обязательное поле
        </div>
      </div>
      <div class="form-group">
        <label for="bib">bib</label>
        <input type="text" class="form-control" id="bib" required [(ngModel)]="newTeamModel.bib" name="bib" #name="ngModel">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Обязательное поле
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
      Save
    </button>
  </div>
</ng-template>
