import {Component, OnInit} from '@angular/core';
import {Group, Participant, Team, TeamsAndGroups, UpdateLinks} from "../../domain/models";
import {ActivatedRoute} from "@angular/router";
import {FractalService} from "../../services/fractal/fractal.service";
import {Title} from "@angular/platform-browser";
import {NotificationService} from "../../services/notifications/notification.service";
import {ModelGroup} from "../participants-manage/participants-manage.component";

@Component({
  selector: 'app-team-info',
  templateUrl: './team-info.component.html',
  styleUrls: ['./team-info.component.scss']
})
export class TeamInfoComponent implements OnInit {

  team: Team;
  teamId: string;

  modelGroup: ModelGroup = new ModelGroup();
  groups: Group[];
  teamsAndGroups: TeamsAndGroups;
  teams: Team[];
  eventId: string = '';
  dayId: string = '';
  searchText = '';

  constructor(
    private route: ActivatedRoute,
    private api: FractalService,
    private titleService: Title,
    private notifyService: NotificationService
  ) {
    this.teamId = this.route.snapshot.params.id;
    this.modelGroup.teamId = this.teamId;
    titleService.setTitle("Изменение команды")
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.eventId = params['eventId'] || '';
      this.dayId = params['dayId'] || '';
      this.api.getTeamById(this.teamId).subscribe(res => {
        this.team = res;
      });
      this.api.getTeamsAndGroups(this.eventId, this.dayId).subscribe(g => {
        this.teamsAndGroups = g;
      });
    });
  }

  link(group: Group) {
    this.notifyService.showInfo("Привязываем...");
    this.api.linkTeamToOtherGroupSimple(this.eventId, this.dayId, this.teamId, new UpdateLinks(group.id, '')).subscribe(res => {
      this.notifyService.showSuccess(res);
    });
  }

  unlink(group: Group) {
    this.notifyService.showInfo("Отвязываем...");
    this.api.unlinkTeamToOtherGroupSimple(this.eventId, this.dayId, this.teamId, new UpdateLinks(group.id, '')).subscribe(res => {
      this.notifyService.showSuccess(res);
    });
  }

  unlinkParticipant(participant: Participant) {
    this.notifyService.showInfo("Отвязываем...");
    this.api.unlinkParticipant(this.teamId, participant.id).subscribe(res => {
      this.notifyService.showSuccess(res);
    });
  }

  save() {
    console.log(this.team);
    this.api.updateTeamById(this.teamId, this.team).subscribe(res => {
        this.notifyService.showSuccess("Обновлено")
      }
    )
  }

}
