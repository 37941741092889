import * as moment from 'moment';
import {
  AllowedControlPoint,
  ControlPointsSettings,
  Device,
  DeviceInfo,
  DeviceStateData,
  ExtendControlPoint,
  GpsData,
  LogsData,
  ParticipantWithDeviceDto,
  Punch,
  TeamLocal
} from './models';
import {millisToTime, timeToMillis, utcToLocal} from './date-utils';

//Results
export function getResultTimeByTeam(team: TeamLocal) {
  return Math.max.apply(Math, team.runners.map(function(o) {
    try {
      const finishTime = getResultTime(o);
      return utcToLocal(finishTime);
    } catch (error) {
      return '';
    }
  }));
}

export function getResultTime(participant: ParticipantWithDeviceDto): string {
  let foundFinish;
  let foundStart;
  let difference;
  try {
    foundFinish = getFinishTime(participant);
    foundStart = getStartTimeInMillis(participant);
    if (foundStart == null) {
      foundStart = participant.controlStartTime;
    }
    difference = new Date(foundFinish).valueOf() - new Date(foundStart).valueOf();
    return moment.utc(new Date(difference)).utc().format('HH:mm:ss');
  } catch (error) {
    // console.log(error)
    try {
      foundFinish = (participant.newDevice).punches.find(e => e.code == 240).timestamp;
      foundStart = participant.controlStartTime;
      difference = new Date(foundFinish).valueOf() - new Date(foundStart).valueOf();
      return difference.toString();
    } catch (error) {
      // console.log(error)
      return '-';
    }
  }
}

export function associatePunchToSettings2(punch: Punch, settings: ControlPointsSettings) {
  const foundSettings = settings?.allowedControlPoints?.find(x => {
    return punch.uuid.replace('-', '') == x.settings.uuid.replace('-', '') && punch.major == x.settings.major && punch.minor == x.settings.minor;
  });
  if (foundSettings != null) {
    const cp1 = new ExtendControlPoint();
    cp1.code = foundSettings?.code;
    cp1.name = foundSettings?.name;
    cp1.timestamp = punch.timestamp;
    cp1.price = foundSettings?.price;
    cp1.isDuplicate = punch.isDuplicate;
    cp1.tag = punch.packetId
    return cp1;
  } else {
    return null;
  }
}

export class NewDevice {
  id: string = '';
  imaginaryId: string = 'default';
  deviceInfo: DeviceInfo;
  createdDate: string = 'defa';
  punches: Array<ExtendControlPoint> = [];
  gpsData: GpsData;
  deviceStateData: DeviceStateData;
  logsData: LogsData;
  lastActiveTime: string;
  toCheck: AllowedControlPoint[];
}


export function associateDevicePunchesToControlPoints(device: Device, controlPointsSettings: ControlPointsSettings): NewDevice {
  const f = Array<ExtendControlPoint>();
  const newDevice = new NewDevice();
  newDevice.createdDate = device?.createdDate;
  newDevice.deviceInfo = device?.deviceInfo;
  newDevice.deviceStateData = device?.deviceStateData;
  newDevice.gpsData = device?.gpsData;
  newDevice.imaginaryId = device?.imaginaryId;
  newDevice.lastActiveTime = device?.lastActiveTime;
  newDevice.logsData = device?.logsData;
  device?.punchData?.punches?.forEach(p => {
    const s = associatePunchToSettings2(p, controlPointsSettings);
    if (s != null) {
      f.push(s);
    }
  });

  newDevice.punches = f;
  return newDevice;
}

export function associatePunchToSettings(punch: Punch, settings: ControlPointsSettings): ExtendControlPoint {
  if (settings == null) {
    const cp = new ExtendControlPoint();
    cp.code = punch.minor;
    cp.name = punch.minor?.toString();
    cp.timestamp = punch.timestamp;
    cp.price = Math.floor((punch.minor / 10));
    cp.major = punch.major;
    cp.uuid = punch.uuid;
    cp.isDuplicate = punch.isDuplicate;
    return cp;
  }
  let found = null;
  const foundSettings = settings.allowedControlPoints.find(x => {
    const equals = punch.uuid === x.settings.uuid && punch.major === x.settings.major && punch.minor === x.settings.minor;
    found = x;
    equals;
  });
  const cp1 = new ExtendControlPoint();
  cp1.code = found.settings.minor;
  cp1.name = found.name;
  cp1.timestamp = punch.timestamp;
  cp1.price = found.price;
  cp1.isDuplicate = punch.isDuplicate;
  cp1.major = punch.major;
  cp1.uuid = punch.uuid;
  return cp1;
}

export function score(device: NewDevice): string {
  let testScore = 0;
  if (device == null) {
    return '0';
  }
  const punches = device.punches.filter(p => p.isDuplicate == false);

  if (punches.length != 0) {
    punches.forEach(function(value) {
      if (value.code == 240 || value.code == 241) {

      } else {
        // if (value.code < 100) {
        let x = Math.floor((value.code / 10));

        testScore += value.price;
        // }
      }

    });
  }

  return testScore.toString();
}

export function scoreNew(device: NewDevice): number {
  let score = 0;
  if (device == null) {
    return 0;
  }
  const punches = device.punches.filter(p => p.isDuplicate == false);

  if (punches.length != 0) {
    punches.forEach(function(value) {
      if (value.code == 240 || value.code == 241) {

      } else {
        score += value.price;
      }
    });
  }

  return score;
}

export function getPenalty(startTime: string, finishTime: string) {
  try {
    const t1 = Date.parse(startTime);
    const t2 = Date.parse(finishTime);

    const diffInMillis = t2 - t1;
    const diffInSeconds = Math.floor((diffInMillis / 1000));
    if (diffInSeconds <= 0) {
      return 0;
    } else {
      const minutes = Math.floor(diffInSeconds / 60);
      if (minutes == 0) {
        if (diffInSeconds == 0) {
          return 0;
        } else {
          return 1;
        }
      } else {
        const secondsLeft = diffInSeconds - (minutes * 60);
        if (secondsLeft == 0) {
          return minutes;
        } else {
          return (minutes + 1);
        }
      }
    }
  } catch (error) {
    return 0;
  }
}

export function sortTeams(teams: TeamLocal[]) {
  return teams?.sort(function(team, ti) {
    let a = getResultTimeByTeam(team);
    let b = getResultTimeByTeam(ti);
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  });
}

export function getStartTime(participant: ParticipantWithDeviceDto) {
  const finishPunch = participant?.newDevice?.punches?.find(e => e.code == 241);
  if (finishPunch == null) {
    return utcToLocal(participant.controlStartTime);
  } else {
    return utcToLocal(finishPunch.timestamp);
  }
}

export function getStartTimeInMillis(participant: ParticipantWithDeviceDto) {
  const finishPunch = participant?.newDevice?.punches?.find(e => e.code == 241);
  if (finishPunch == null) {
    return participant.controlStartTime;
  } else {
    return finishPunch.timestamp;
  }
}

export function getFinishTime(participant: ParticipantWithDeviceDto) {
  const finishPunch1 = getFinishTimeInMillis(participant);
  if (finishPunch1 == null) {
    return '-';
  } else {
    return millisToTime(finishPunch1);
  }
}

export function getFinishTimeInMillis(participant: ParticipantWithDeviceDto): number {
  try {
    let lastFinishPunch: ExtendControlPoint = null;
    let lastNotFinishPunch: ExtendControlPoint = null;
    let lastFinishPunchIndex: number = null;
    let lastNotFinishPunchIndex: number = null;
    const punchesAll = participant?.newDevice?.punches?.sort(function(a, b) {
      if (a.timestamp > b.timestamp) {
        return -1;
      }
      if (a.timestamp < a.timestamp) {
        return 1;
      }
      return 0;
    });
    punchesAll.filter(p => p.isDuplicate == false)?.forEach((v, i) => {
      if (v.code != 240) {
        lastNotFinishPunch = v;
        lastNotFinishPunchIndex = i;
      }
    });

    const BreakException = {};

    try {
      const p = punchesAll[punchesAll.length - 1];
      if (p.code === 240) {
        lastFinishPunch = p;
        lastFinishPunchIndex = punchesAll.length - 1;
      } else {
        punchesAll?.forEach((v, i) => {
            if (v.code == 240 && i >= lastFinishPunchIndex) {
              lastFinishPunch = v;
              lastFinishPunchIndex = i;
              throw BreakException;
            }
          }
        );
      }
    } catch (e) {

    }

    if (lastFinishPunch == null) {
      return -1;
    } else {
      return timeToMillis(lastFinishPunch.timestamp);
    }
  } catch (error) {
    return -1;
  }
}
