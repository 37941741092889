import { range } from "rxjs";
import { AllowedControlPoint, ControlPointsSettings, Location, Settings } from "../domain/models";

export function getControlPointsSettingsFromRange(a: number, b: number, major: number, punchAlgId: number): Array<AllowedControlPoint> {
    var arr: Array<AllowedControlPoint> = Array(b - a);

    var index = 0; 
    //TODO fix control points less than 100 not created
    for (let i = a; i <= b; i++) {
        var cp = new AllowedControlPoint();
        var settings = new Settings();
        var location = new Location();
        location.accuracy = 0;
        location.latitude = 0;
        location.longitude = 0;
        settings.uuid = "74278bda-b644-4520-8f0c-720eaf059935";
        settings.major = major;
        settings.minor = i;
        settings.punchAlgorithmId = punchAlgId.toString();
        cp.name = i.toString();
        cp.code = i;
        cp.description = "";
        cp.location = location;
        cp.price = parseInt((i / 10).toString());
        cp.settings = settings;
        arr[index] = cp;
        index = index + 1;
    }
    return arr;
}

export function getControlPointsSettingsFromRangeWithPrice(a: number, b: number, major: number, punchAlgId: number, defaultPrice: number): Array<AllowedControlPoint> {
    var arr: Array<AllowedControlPoint> = Array(b - a);

    var index = 0;
    for (let i = a; i <= b; i++) {
        var cp = new AllowedControlPoint();
        var settings = new Settings();
        var location = new Location();
        location.accuracy = 0;
        location.latitude = 0;
        location.longitude = 0;
        settings.uuid = "74278bda-b644-4520-8f0c-720eaf059935";
        settings.major = major;
        settings.minor = i;
        settings.punchAlgorithmId = punchAlgId.toString();
        cp.name = i.toString();
        cp.code = i;
        cp.description = "";
        cp.location = location;
        cp.price = parseInt(defaultPrice.toString());
        cp.settings = settings;
        arr[index] = cp;
        index = index + 1;
    }
    return arr;
}