<div>
  <p class="header">{{currentEvent?.name}}</p>
  <p class="header">Протокол результатов по состоянию на: {{currentTime}}</p>
  <div class="div-center">
    <a type="button" target="_blank" class="button-main" [attr.href]="currentEvent?.ogpsEventUrl">Трансляция OGPS</a>
  </div>
  <div class="div-center" *ngIf="isLoading">
    <progress class="progress-indicator">
    </progress>
  </div>

  <div *ngIf="!isLoading" class="div-default">
    <div class="div-center">
      <p class="text-default-inline" *ngFor="let p of sortedGroups">
        <a (click)="scroll(p)">{{p}}</a>
      </p>
    </div>
    <div *ngFor="let p of data" class="div-center">
      <p id="{{p.groupName}}" class="table-header">{{p.groupName}}</p>
      <div>
        <table class="table">
          <thead>
          <tr>
            <th>#</th>
            <th>bib</th>
            <th>Фамилия</th>
            <th>Имя</th>
            <th>Дата рождения</th>
            <th>Команда</th>
            <th>Старт</th>
            <th>Финиш</th>
            <th>Время в пути</th>
            <th>Баллы</th>
            <th>Штраф</th>
            <th>Результат</th>
            <th>Сплит</th>
            <th>Статус</th>
            <th>Действия</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let team of p.teams">
            <td>{{p.teams.indexOf(team) + 1}}</td>
            <td>
              <div class="device-details" *ngFor="let participant of team.runners">
                <p class="p-base">{{participant.bib}}</p>
              </div>
            </td>
            <td>
              <div class="device-details" *ngFor="let participant of team.runners">
                <p class="p-base">{{participant.surname}}</p>
              </div>
            </td>
            <td>
              <div class="device-details" *ngFor="let participant of team.runners">
                <p class="p-base">{{participant.name}}</p>
              </div>
            </td>
            <td>
              <div class="device-details" *ngFor="let participant of team.runners">
                <p class="p-base">{{participant.birthDate}}</p>
              </div>
            </td>
            <td>{{ team.name }}</td>
            <td>
              <div class="device-details" *ngFor="let participant of team.runners">
                <p class="p-base">{{getStartTime(participant)}}</p>
              </div>
            </td>
            <td>
              <div class="device-details" *ngFor="let participant of team.runners">
                <p class="p-base">{{getFinishTimeFromParticipant(participant)}}</p>
              </div>
            </td>
            <td>
              <div class="device-details" *ngFor="let participant of team.runners">
                <p class="p-base">{{getDifferenceBetweenTimes(participant)}}</p>
              </div>
            </td>

            <td>
              <div *ngFor="let participant of team.runners">
                <div class="device-details">
                  <p class="p-base">
                    {{score(participant.newDevice, participant.controlPointsSettingsId)}}</p>
                </div>
              </div>
            </td>
            <td>
              <div  class="device-details" *ngFor="let participant of team.runners">
                <p class="p-base">{{getPenalty(participant)}}</p>
              </div>
            </td>
            <td>
              <div class="device-details" *ngFor="let participant of team.runners">
                <p class="p-base">{{getResult(participant)}}</p>
              </div>
            </td>
            <td>
              <div *ngFor="let participant of team.runners">
                <div class="device-details">
                  <p class="p-base">
                    <a class="button-link" target="_blank" routerLink="/devices/{{participant?.device?.id}}"
                       [queryParams]="{ controlPointsSettingsId: participant?.controlPointsSettingsId, controlStartTime: participant?.controlStartTime }"
                       *ngIf="participant?.device?.id!=null">Сплиты</a>
                  </p>
                </div>
              </div>
            </td>
            <td>
              <div *ngFor="let participant of team.runners">
                <div class="device-details">
                  <p class="p-base">
                    {{getParticipantStatus(participant)}}</p>
                </div>
              </div>
            </td>

            <td>
              <div>
                <div class="device-details">
                  <button class="button-main" (click)="generatePdf(team.runners, p?.teams?.indexOf(team) + 1, p?.groupName)">PDF</button>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
