import {HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {UrlUtils} from '../utils/url-utils';
import {
  BaseReponse,
  ControlPointsSettings,
  Device,
  DeviceIds,
  DeviceState,
  Event,
  EventDay,
  EventResults,
  EventResultsNew,
  Format,
  GpsPoint,
  GpsSettings,
  Group,
  Participant,
  Punch,
  PunchAlgorithm,
  Team,
  TeamsAndGroups,
  UpdateLinks,
  UploadCSV,
  UploadPunches
} from 'src/app/domain/models';
import {NotificationService} from "../notifications/notification.service";

@Injectable({
  providedIn: 'root'
})
export class FractalService {

  private baseUrl = UrlUtils.getProdServer();
  private SLASH = '/';
  private EVENTS = 'events';
  private DAYS = 'days';
  private SETTINGS = 'settings';
  private GPS = 'gps';
  private PUNCH_ALGORITHMS = 'punch-algorithms';
  private CONTROL_POINTS = 'control-points';
  private STATE = 'state';
  private RESULTS = 'results';
  private FORMATS = 'formats';
  private PUNCHES = 'punches';
  private GROUPS = 'groups';
  private TEAMS_GROUPS = 'teams-groups';
  private TEAMS = 'teams';
  private PARTICIPANTS = 'participants';
  private DEVICES = 'devices';
  private URI_EVENTS = this.baseUrl + this.SLASH + this.EVENTS;
  private URI_GROUPS = this.baseUrl + this.SLASH + this.GROUPS;
  private URI_TEAMS = this.baseUrl + this.SLASH + this.TEAMS;
  private URI_PARTICIPANTS = this.baseUrl + this.SLASH + this.PARTICIPANTS;
  private URI_DEVICES = this.baseUrl + this.SLASH + this.DEVICES;
  private URI_GPS_SETTINGS = this.baseUrl + this.SLASH + this.SETTINGS + this.SLASH + this.GPS;
  private URI_PUNCH_ALGORITHMS = this.baseUrl + this.SLASH + this.SETTINGS + this.SLASH + this.PUNCH_ALGORITHMS;
  private URI_CONTROL_POINTS_SETTINGS = this.baseUrl + this.SLASH + this.SETTINGS + this.SLASH + this.CONTROL_POINTS;

  private POINTS = "points-ids"
  private gpsUrl = UrlUtils.getProdGpsServer();
  private URI_GPS_GET = this.gpsUrl + this.SLASH + this.POINTS;

  constructor(
    private http: HttpClient,
    private notifyService: NotificationService
  ) {
  }

  getEvents(): Observable<Event[]> {
    console.log(this.URI_EVENTS);
    return this.http.get<Event[]>(this.URI_EVENTS)
    // .pipe(
    //   catchError(this.handleError<Event[]>('getEvents', []))
    // );
  }

  getEvent(eventId: string): Observable<Event> {
    return this.http.get<Event>(this.URI_EVENTS + this.SLASH + eventId);
  }

  createEvent(event: Event): Observable<string> {
    return this.http.post<string>(this.URI_EVENTS, event)
      .pipe(
        // catchError(error => {
        //   if (error.error instanceof BaseResponse) {
        //     `Error: ${error.errors}`;
        //   } else {
        //     `Error: ${error.message}`;
        //   }
        //   return of('');
        // })
        catchError(this.handleError<string>('getEvents', ''))
      );
  }

  updateEvent(event: Event): Observable<Event> {
    return this.http.patch<Event>(this.URI_EVENTS + this.SLASH + event.id, event);
  }

  getEventDays(eventId: string): Observable<EventDay[]> {
    return this.http.get<EventDay[]>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS)
      .pipe(
        catchError(this.handleError<EventDay[]>('getEventDays', []))
      );
  }

  createEventDay(eventId: string, event: EventDay): Observable<String> {
    return this.http.post<String>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS, event)
      .pipe(
        catchError(this.handleError<String>('getEvents', ''))
      );
  }

  getEventDay(eventId: string, dayId: string): Observable<EventDay> {
    return this.http.get<EventDay>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId)
      .pipe(
        catchError(this.handleError<EventDay>('getEventDay', null))
      );
  }

  GetDevice(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.DEVICES}/${id}`)
      .pipe(
        map((res: Response) => {
          return res || {};
        }),
        // catchError(this.handleError)
      );
  }

  // Error handling
  HandleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }


  GetControlPointsSettings(id: string): Observable<ControlPointsSettings> {
    return this.http.get<ControlPointsSettings>(this.URI_CONTROL_POINTS_SETTINGS + this.SLASH + id);
  }

  updateEventDay(eventId: string, day: EventDay): Observable<EventDay> {
    return this.http.patch<EventDay>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + day.id, day);
  }

  getDevicesState(eventId: string, dayId: string): Observable<DeviceState[]> {
    return this.http.get<DeviceState[]>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.STATE);
  }

  getResults(eventId: string, dayId: string): Observable<EventResults> {
    return this.http.get<EventResults>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.RESULTS);
  }

  createFormat(eventId: string, dayId: string, format: Format): Observable<String> {
    return this.http.post<String>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.FORMATS, format)
      .pipe(
        catchError(this.handleError<String>('createFormat', ''))
      );
  }

  getFormat(eventId: string, dayId: string, formatId: string): Observable<Format> {
    return this.http.get<Format>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.FORMATS + this.SLASH + formatId);
  }

  updateFormat(eventId: string, dayId: string, format: Format): Observable<Format> {
    return this.http.patch<Format>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.FORMATS + this.SLASH + format.id, format);
  }

  getFormats(eventId: string, dayId: string): Observable<Format[]> {
    return this.http.get<Format[]>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.FORMATS)
      .pipe(
        catchError(this.handleError<Format[]>('getFormats', []))
      );
  }

  createGroup(eventId: string, dayId: string, formatId: string, group: Group): Observable<String> {
    return this.http.post<String>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.FORMATS + this.SLASH + formatId + this.SLASH + this.GROUPS, group)
      .pipe(
        catchError(this.handleError<String>('createGroup', ''))
      );
  }

  getGroups(eventId: string, dayId: string, formatId: string): Observable<Group[]> {
    return this.http.get<Group[]>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.FORMATS + this.SLASH + formatId + this.SLASH + this.GROUPS)
      // return this.http.get<Group[]>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.GROUPS)
      .pipe(
        catchError(this.handleError<Group[]>('getFormats', []))
      );
  }

  getTeamsAndGroups(eventId: string, dayId: string): Observable<TeamsAndGroups> {
    return this.http.get<TeamsAndGroups>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.TEAMS_GROUPS)
      .pipe(
        catchError(this.handleError<TeamsAndGroups>('getFormats', new TeamsAndGroups()))
      );
  }

  getGroup(eventId: string, dayId: string, formatId: string, groupId: string): Observable<Group> {
    return this.http.get<Group>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.FORMATS + this.SLASH + formatId + this.SLASH + this.GROUPS + this.SLASH + groupId)
      .pipe(
        catchError(this.handleError<Group>('getTeams', new Group()))
      );
  }

  getGroupById(groupId: string): Observable<Group> {
    return this.http.get<Group>(this.baseUrl + this.SLASH + this.GROUPS + this.SLASH + groupId)
      .pipe(
        catchError(this.handleError<Group>('getTeams', new Group()))
      );
  }

  updateGroup(eventId: string, dayId: string, formatId: string, groupId: string, team: Group): Observable<Group> {
    return this.http.patch<Group>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.FORMATS + this.SLASH + formatId + this.SLASH + this.GROUPS + this.SLASH + groupId, team);
  }

  updateGroupById(groupId: string, team: Group): Observable<Group> {
    return this.http.patch<Group>(this.URI_GROUPS + this.SLASH + groupId, team);
  }

  getTeams(eventId: string, dayId: string, formatId: string, groupId: string): Observable<Team[]> {
    return this.http.get<Team[]>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.FORMATS + this.SLASH + formatId + this.SLASH + this.GROUPS + this.SLASH + groupId + this.SLASH + this.TEAMS)
      .pipe(
        catchError(this.handleError<Team[]>('getTeams', []))
      );
  }

  createTeam(eventId: string, dayId: string, formatId: string, groupId: string, group: Team): Observable<String> {
    return this.http.post<String>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.FORMATS + this.SLASH + formatId + this.SLASH + this.GROUPS + this.SLASH + groupId + this.SLASH + this.TEAMS + this.SLASH, group)
      .pipe(
        catchError(this.handleError<String>('createGroup', ''))
      );
  }

  getTeam(eventId: string, dayId: string, formatId: string, groupId: string, teamId: string): Observable<Team> {
    return this.http.get<Team>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.FORMATS + this.SLASH + formatId + this.SLASH + this.GROUPS + this.SLASH + groupId + this.SLASH + this.TEAMS + this.SLASH + teamId)
      .pipe(
        catchError(this.handleError<Team>('getTeams', new Team()))
      );
  }

  getTeamById(teamId: string): Observable<Team> {
    return this.http.get<Team>(this.URI_TEAMS + this.SLASH + teamId)
  }

  updateTeamById(teamId: string, team: Team): Observable<Team> {
    return this.http.patch<Team>(this.URI_TEAMS + this.SLASH + teamId, team)
  }

  updateTeam(eventId: string, dayId: string, formatId: string, groupId: string, teamId: string, team: Team): Observable<Team> {
    return this.http.patch<Team>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.FORMATS + this.SLASH + formatId + this.SLASH + this.GROUPS + this.SLASH + groupId + this.SLASH + this.TEAMS + this.SLASH + teamId, team);
  }

  createParticipant(eventId: string, dayId: string, formatId: string, groupId: string, teamId: string, group: Participant): Observable<String> {
    return this.http.post<String>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.FORMATS + this.SLASH + formatId + this.SLASH + this.GROUPS + this.SLASH + groupId + this.SLASH + this.TEAMS + this.SLASH + teamId + this.SLASH + 'participants', group)
      .pipe(
        catchError(this.handleError<String>('createGroup', ''))
      );
  }

  getParticipants(eventId: string, dayId: string, formatId: string, groupId: string, teamId: string): Observable<Participant[]> {
    return this.http.get<Participant[]>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.FORMATS + this.SLASH + formatId + this.SLASH + this.GROUPS + this.SLASH + groupId + this.SLASH + this.TEAMS + this.SLASH + teamId + this.SLASH + 'participants')
      .pipe(
        catchError(this.handleError<Participant[]>('getTeams', []))
      );
  }

  linkParticipantToOtherTeam(eventId: string, dayId: string, teamId: string, team: UpdateLinks): Observable<HttpEvent<string>> {
    const opts: any = {
      headers: new HttpHeaders({}),
      responseType: 'text'
    };
    return this.http.patch<string>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.TEAMS + this.SLASH + teamId + this.SLASH + 'update-participant-links', team, opts);
    // return this.http.patch<String>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.TEAMS + this.SLASH + teamId + this.SLASH + 'update-participant-links', team);
  }

  linkTeamToOtherGroup(eventId: string, dayId: string, teamId: string, team: UpdateLinks): Observable<String> {
    return this.http.patch<String>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.TEAMS + this.SLASH + teamId + this.SLASH + 'update-team-links', team);
  }

  linkTeamToOtherGroupSimple(eventId: string, dayId: string, teamId: string, team: UpdateLinks): Observable<String> {
    return this.http.patch<String>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.TEAMS + this.SLASH + teamId + this.SLASH + 'link', team);
  }

  unlinkTeamToOtherGroupSimple(eventId: string, dayId: string, teamId: string, team: UpdateLinks): Observable<String> {
    return this.http.patch<String>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.TEAMS + this.SLASH + teamId + this.SLASH + 'unlink', team);
  }

  getDevices(participantId: string): Observable<Device[]> {
    return this.http.get<Device[]>(this.baseUrl + this.SLASH + this.PARTICIPANTS + this.SLASH + participantId + this.SLASH + this.DEVICES)
  }

  getDevice(deviceId: string): Observable<Device> {
    return this.http.get<Device>(this.baseUrl + this.SLASH + this.DEVICES + this.SLASH + deviceId)
  }

  updateDevice(deviceId: string, device: Device): Observable<Device> {
    return this.http.patch<Device>(this.baseUrl + this.SLASH + this.DEVICES + this.SLASH + deviceId,device)
  }

  getDeviceTrack(deviceId: string): Observable<GpsPoint[]> {
    return this.http.get<GpsPoint[]>(this.baseUrl + this.SLASH + this.DEVICES + this.SLASH + deviceId + this.SLASH + "gps-points")
  }

  uploadPunch(deviceId: string, punches: Punch[]): Observable<UploadPunches> {
    return this.http.post<UploadPunches>(this.baseUrl + this.SLASH + this.DEVICES + this.SLASH + deviceId + this.SLASH + this.PUNCHES, punches);
  }

  linkDeviceToParticipant(deviceId: string, participantId: string): Observable<BaseReponse> {
    return this.http.post<BaseReponse>(this.baseUrl + this.SLASH + this.DEVICES + this.SLASH + deviceId + this.SLASH + "link", null, {
      params: {
        "participantId": participantId,
      }
    })
  }

  restorePunch(deviceId: string, punches: Punch): Observable<UploadPunches> {
    return this.http.put<UploadPunches>(this.baseUrl + this.SLASH + this.DEVICES + this.SLASH + deviceId + this.SLASH + this.PUNCHES + this.SLASH + "restore", punches);
  }

  deletePunch(deviceId: string, punch: Punch): Observable<ArrayBuffer> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: punch,
    };
    return this.http.delete<ArrayBuffer>(this.baseUrl + this.SLASH + 'devices' + this.SLASH + deviceId + this.SLASH + this.PUNCHES, options);
  }

  getParticipant(eventId: string, dayId: string, formatId: string, groupId: string, teamId: string, participantId: string): Observable<Participant> {
    return this.http.get<Participant>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.FORMATS + this.SLASH + formatId + this.SLASH + this.GROUPS + this.SLASH + groupId + this.SLASH + this.TEAMS + this.SLASH + teamId + this.SLASH + this.PARTICIPANTS + this.SLASH + participantId)
      .pipe(
        catchError(this.handleError<Participant>('getTeams', new Participant()))
      );
  }

  getParticipantById(participantId: string): Observable<Participant> {
    return this.http.get<Participant>(this.URI_PARTICIPANTS + this.SLASH + participantId)
  }

  updateParticipantById(id: string, entity: Participant): Observable<Participant> {
    return this.http.patch<Participant>(this.URI_PARTICIPANTS + this.SLASH + id, entity);
  }

  updateParticipant(eventId: string, dayId: string, formatId: string, groupId: string, teamId: string, participantId: string, team: Participant): Observable<Participant> {
    return this.http.patch<Participant>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + this.DAYS + this.SLASH + dayId + this.SLASH + this.FORMATS + this.SLASH + formatId + this.SLASH + this.GROUPS + this.SLASH + groupId + this.SLASH + this.TEAMS + this.SLASH + teamId + this.SLASH + this.PARTICIPANTS + this.SLASH + participantId, team);
  }

  getGpsSettings(): Observable<GpsSettings[]> {
    return this.http.get<GpsSettings[]>(this.URI_GPS_SETTINGS);
  }

  createControlPointsSettings(controlPointsSettings: ControlPointsSettings) {
    return this.http.post<String>(this.URI_CONTROL_POINTS_SETTINGS, controlPointsSettings);
  }

  getControlPointsSettings(): Observable<ControlPointsSettings[]> {
    return this.http.get<ControlPointsSettings[]>(this.URI_CONTROL_POINTS_SETTINGS);
  }

  getControlPointsSettingsById(id: string): Observable<ControlPointsSettings> {
    return this.http.get<ControlPointsSettings>(this.URI_CONTROL_POINTS_SETTINGS + this.SLASH + id);
  }

  updateControlPointsSettingsById(controlPointsSettings: ControlPointsSettings): Observable<ControlPointsSettings> {
    return this.http.patch<ControlPointsSettings>(this.URI_CONTROL_POINTS_SETTINGS + this.SLASH + controlPointsSettings.id, controlPointsSettings);
  }

  createGpsSettings(gpsSettings: GpsSettings): Observable<String> {
    return this.http.post<String>(this.URI_GPS_SETTINGS, gpsSettings);

  }

  updateGpsSettings(gpsSettings: GpsSettings): Observable<GpsSettings> {
    return this.http.patch<GpsSettings>(this.URI_GPS_SETTINGS, gpsSettings);
  }

  createPunchAlgorithm(punchAlgorithm: PunchAlgorithm): Observable<String> {
    return this.http.post<String>(this.URI_PUNCH_ALGORITHMS, punchAlgorithm);

  }

  getPunchAlgorithms(): Observable<PunchAlgorithm[]> {
    return this.http.get<PunchAlgorithm[]>(this.URI_PUNCH_ALGORITHMS);
  }

  getGpsPoints(ids: String[]): Observable<GpsPoint[]> {
    let _ids = new DeviceIds();
    _ids.ids = ids;
    return this.http.post<GpsPoint[]>(this.URI_GPS_GET, _ids);
  }

  getResultsNew(eventId: string, dayId: string): Observable<EventResultsNew> {
    return this.http.get<EventResultsNew>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + "days" + this.SLASH + dayId + this.SLASH + "results2")
  }

  GetDay(eventId: string, id: string): Observable<any> {
    return this.http.get<EventDay>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + "days" + this.SLASH + id)
      .pipe(
        catchError(this.handleError())
      )
  }

  uploadCsv(eventId: string, dayId: string, uploadCsv: UploadCSV): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + "days" + this.SLASH + dayId + this.SLASH + "participants/upload-csv", uploadCsv.data, {
      params: {
        "autoCreate": "true",
        "gpsSettingsId": uploadCsv.gpsSettingsId,
        "controlPointsSettingsId": uploadCsv.controlPointsSettingsId,
        "punchAlgorithmId": uploadCsv.punchAlgorithmId,
        "type": "sfr"
      }
    });
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  // eslint-disable-next-line
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: BaseResponse): Observable<T> => {
      console.log(result);
      console.log(error);
      // this.notifyService.showError(err.errors);
      // Let the app keep running by returning an empty result.
      return throwError(error)
      // return of(result as T);
    };
  }

  unlinkParticipant(teamId: string, id: string) {
    const opts: any = {
      headers: new HttpHeaders({}),
      responseType: 'text'
    };
    return this.http.patch<string>(this.URI_TEAMS + this.SLASH + teamId + this.SLASH + this.PARTICIPANTS + this.SLASH + id + this.SLASH + "unlink", opts);
  }

  registerDevice(participantId: string): Observable<Device> {
    const device = new Device();
    device.imaginaryId = "admin-created";
    return this.http.post<Device>(this.URI_PARTICIPANTS + this.SLASH + participantId + this.SLASH + this.DEVICES, {
      "imaginaryId": "admin-add"
    });
  }
}

export class BaseResponse {
  response: any;
  errors: BaseError[];
}

export class BaseError {
  code: string;
  message: string;
  details: string;
}
