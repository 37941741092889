import * as moment from 'moment';

export const PATTERN_FULL_DATE = "yyyy-MM-DD HH:mm:ss.SSS";
export const PATTERN_UTC = "yyyy-MM-DDTHH:mm:ss";

export function utcToLocal(time: string, pattern: string = 'HH:mm:ss'): string {
  try {
    return moment.utc(time).local().format(pattern);
  } catch (e) {
    return null;
  }
}

export function timeToMillis(time: string): number {
  try {
    return moment.utc(time).local().milliseconds();
  } catch (e) {
    return null;
  }
}

export function millisToTime(time: number, pattern: string = 'HH:mm:ss'): string {
  if (time <= 0) {
    return null;
  }
  try {
    return moment.utc(time).local().format(pattern);
  } catch (e) {
    return null;
  }
}

export function millisToTimeUtc(time: number, pattern: string = 'HH:mm:ss'): string {
  if (time == 0) {
    return '';
  }
  return moment.utc(time).format(pattern);
}
