<div class="div-center">
  <div id="map"></div>
  <h5>Точность анализа (метры)</h5>
  <input type="text" class="form-control" style="margin: 20px;" id="deviceIdForCopy"
         [(ngModel)]="analyzeParameters.accuracy"
         name="deviceIdForCopy" placeholder="">
  <button class="btn btn-success mr-1" style="margin: 20px;" (click)="reloadAll()" [hidden]="isLoading">Обновить всё
  </button>
  <button class="btn btn-success mr-1" style="margin: 20px;" (click)="analyze()" [hidden]="isLoading">Анализ</button>
  <!--  <button class="btn btn-success mr-1" style="margin: 20px;" (click)="switchMap()">Показать/Скрыть карту</button>-->
</div>
<div>
  <table class="table">
    <thead>
    <tr>
      <th>Bib</th>
      <th>Participant</th>
      <th>GPS</th>
      <th>Waiting approve</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let t of data?.tracks" style="background-color: #000000;">
      <tr style="background-color: white;">
        <td style="background-color: white">{{ t?.participant?.bib }}</td>
        <td style="background-color: white">{{ t?.participant?.name }} {{ t?.participant?.surname }}</td>
        <td style="background-color: white">{{ t?.gpsTrack?.length }}</td>
        <td style="background-color: white">{{ t?.waitingApprovePunches?.length }}</td>
        <td style="background-color: white">
          <button class="btn btn-secondary mr-1" style="margin: 20px;" (click)="showOnMap(t)" [hidden]="isLoading">Show on map</button>
          <button class="btn btn-light mr-1" style="margin: 20px;" (click)="t.expanded = !t.expanded" [hidden]="isLoading">Expand</button>
        </td>
      </tr>
<!--      <div class="expand-block" [hidden]="!t.expanded">-->
        <ng-container *ngIf="t.expanded">
          <tr style="background-color: #bdbdbd" *ngFor="let p of t?.waitingApprovePunches">
            <td style="background-color: #bdbdbd">{{ p?.major }}</td>
            <td style="background-color: #bdbdbd">{{ p?.minor }}</td>
            <td style="background-color: #bdbdbd">{{ p?.nearestDistance }}</td>
            <td style="background-color: #bdbdbd">{{ p?.timestamp }}</td>
            <td style="background-color: #bdbdbd">
              <button class="btn btn-success mr-1" style="margin: 20px;" (click)="approve(p,t)">Approve</button>
            </td>
          </tr>
        </ng-container>
<!--      </div>-->
    </ng-container>
    </tbody>
  </table>
</div>
