import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FractalService} from "../../services/fractal/fractal.service";
import {Title} from "@angular/platform-browser";
import {NotificationService} from "../../services/notifications/notification.service";
import {ControlPointsSettings, UpdateControlPoints} from "../../domain/models";
import {ModalDismissReasons, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PreferencesService} from "../../services/preferences/preferences.service";
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-device-info',
  templateUrl: './edit-control-points.component.html',
  styleUrls: ['./edit-control-points.component.scss']
})
export class EditControlPointsComponent implements OnInit {

  cpSettingsId: string = "";
  controlPointsSettings: ControlPointsSettings = new ControlPointsSettings();

  static map;
  updateControlPoints: UpdateControlPoints = new UpdateControlPoints();
  closeResult = '';

  constructor(
    private route: ActivatedRoute,
    private api: FractalService,
    private titleService: Title,
    private modalService: NgbModal,
    private notifyService: NotificationService,
    private preferencesService: PreferencesService,
    private clipboard: Clipboard,
  ) {
    titleService?.setTitle('Контрольные точки');

  }

  ngOnInit(): void {
    this.cpSettingsId = this.route.snapshot.params.id;
    this.getDevice()
  }

  ngAfterViewInit(): void {
  }

  getDevice() {
    this.api.getControlPointsSettingsById(this.cpSettingsId).subscribe(res => {
      this.controlPointsSettings = res;
    })
  }

  update() {
    this.api.updateControlPointsSettingsById(this.controlPointsSettings).subscribe(res => {
      this.notifyService.showSuccess("Обновлено")
    });
  }

  openUpdateDialog(content) {
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.parseCsv()
      console.log(this.updateControlPoints);
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult =
        `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  parseCsv() {
    //TODO remove first line
    var newCpSettings = this.controlPointsSettings;
    this.updateControlPoints.data.split("\n").forEach((x, index) => {
      if (index != 0) {
        var allowedControlPoint = newCpSettings.allowedControlPoints.find(cp => cp.code == +x.split("\t")[1])
        x.split("\t").forEach((y, indexY) => {
          switch (indexY) {
            case 0: {
              allowedControlPoint.name = y
              break
            }
            case 1: {
              allowedControlPoint.code = +y
              break
            }
            case 2: {
              allowedControlPoint.settings.minor = +y
              break
            }
            case 3: {
              allowedControlPoint.settings.major = +y
              break
            }
            case 4: {
              allowedControlPoint.location.latitude = +y
              break
            }
            case 5: {
              allowedControlPoint.location.longitude = +y
              break
            }
            case 6: {
              allowedControlPoint.beginWorkingTime = y
              break
            }
            case 7: {
              allowedControlPoint.endWorkingTime = y
              break
            }
            case 8: {
              break
            }
            case 9:
              break
          }
        })
      }
    })
    this.update()
    console.log(this.controlPointsSettings)
  }

  private getDismissReason(reason: any): string {
    console.log(reason);
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  copyAsCSV() {
    var tab = "\t"
    var crlf = "\n"
    var s = "name" + tab + "code" + tab + "minor" + tab + "major" + tab + "latitude" + tab + "longitude" + tab + "beginWorkingTime" + tab + "endWorkingTime" + tab + "qrHash" + crlf
    this.controlPointsSettings.allowedControlPoints.forEach(x => {
      s += x.name + tab + x.code + tab + x.settings.minor + tab + x.settings.major + tab + x.location.latitude + tab + x.location.longitude + tab + x.beginWorkingTime + tab + x.endWorkingTime + tab + x.qrHash + crlf
    })
    this.clipboard.copy(s)
    this.notifyService.showSuccess("Copied to clipboard!")
  }
}
