<div class="content">
  <div style="display: inline;">
    <h3 class="title-base-left">Название</h3>
    <input type="text" class="form-control" [(ngModel)]="group.name" placeholder=""
           name="oldTeamId" #name="ngModel">
  </div>

  <div style="display: inline;">
    <h3 class="title-base-left">Значение</h3>
    <input type="text" class="form-control" [(ngModel)]="group.value" placeholder=""
           name="oldTeamId" #name="ngModel">
  </div>

  <div style="display: inline;">
    <h3 class="title-base-left">Время старта</h3>
    <input type="text" class="form-control" [(ngModel)]="group.controlStartTime" placeholder=""
           name="oldTeamId" #name="ngModel">
  </div>

  <div style="display: inline;">
    <h3 class="title-base-left">Время финиша</h3>
    <input type="text" class="form-control" [(ngModel)]="group.controlFinishTime" placeholder=""
           name="oldTeamId" #name="ngModel">
  </div>

  <div style="display: inline;">
    <h3 class="title-base-left">Контрольное время в секундах</h3>
    <input type="text" class="form-control" [(ngModel)]="group.controlTimeInSeconds" placeholder=""
           name="oldTeamId" #name="ngModel">
  </div>

  <div style="margin: 20px;">
    <button type="button" class="btn btn-primary" (click)="save()">Сохранить</button>
  </div>
</div>
