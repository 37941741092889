<div class="sidebar">
  <ul class="nav">
    <li *ngFor="let item of menuItems" [ngClass]="{'has-submenu': item.submenu}">
      <a [routerLink]="item.route" routerLinkActive="active">{{ item.title }}</a>
      <ul *ngIf="item.submenu" class="submenu">
        <li *ngFor="let subitem of item.submenu">
          <a [routerLink]="subitem.route" routerLinkActive="active">{{ subitem.title }}</a>
        </li>
      </ul>
    </li>
  </ul>
</div>

<router-outlet></router-outlet>
