import {Component, OnInit} from '@angular/core';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ControlPointsSettings} from 'src/app/domain/models';
import {
  getControlPointsSettingsFromRange,
  getControlPointsSettingsFromRangeWithPrice
} from 'src/app/helpers/control-points-utils';
import {FractalService} from 'src/app/services/fractal/fractal.service';
import {NotificationService} from "../../services/notifications/notification.service";
import {timeToMillis} from "../../domain/date-utils";

@Component({
  selector: 'app-control-points-settings',
  templateUrl: './control-points-settings.component.html',
  styleUrls: ['./control-points-settings.component.scss']
})
export class ControlPointsSettingsComponent implements OnInit {

  controlPointsSettings: ControlPointsSettings[];
  newModel: ControlPointsSettings = new ControlPointsSettings();
  isUpdateEventEnabled = false;
  closeResult = '';
  model = {
    name: '',
    beginCp: 0,
    endCp: 100,
    punchAlgorithmId: 0,
    major: 52671,
    startCpUsed: false,
    finishCpMinor: 240,
    defaultPrice: -1
  };

  constructor(
    private fractalService: FractalService,
    private modalService: NgbModal,
    private notifyService: NotificationService
  ) {
  }

  ngOnInit() {
    this.getGpsSettings();
  }

  getGpsSettings() {
    this.fractalService.getControlPointsSettings().subscribe(res => {
      this.controlPointsSettings = res.sort((a, b) => {
        console.log(a.updatedAt)
        if (a?.updatedAt == null && b?.updatedAt == null) return 0
        if (a?.updatedAt == null && b?.updatedAt != null) return 1
        if (a?.updatedAt != null && b?.updatedAt == null) return -1
        if (timeToMillis(a?.updatedAt) > timeToMillis(b?.updatedAt)) return -1
        if (timeToMillis(a?.updatedAt) < timeToMillis(b?.updatedAt)) return 1
        return 0;
      });
    });
  }

  createGpsSettings() {
    console.log(this.model);
    if (this.model.defaultPrice == -1) {
      var arr = getControlPointsSettingsFromRange(this.model.beginCp, this.model.endCp, this.model.major, this.model.punchAlgorithmId);
      var toAdd = 1;
      if (this.model.startCpUsed) {
        toAdd = toAdd++;
      }
      var finishCp = getControlPointsSettingsFromRangeWithPrice(this.model.finishCpMinor, this.model.finishCpMinor, this.model.major, this.model.punchAlgorithmId, 0);
      var newArr = arr.concat(finishCp);
      if (this.model.startCpUsed) {
        var fa = getControlPointsSettingsFromRangeWithPrice(241, 241, this.model.major, this.model.punchAlgorithmId, 0);
        var d = newArr.concat(fa);
        newArr = d;
      }
      console.log(newArr);
      var cpSettings = new ControlPointsSettings();
      cpSettings.id = null
      cpSettings.allowedControlPoints = newArr;
      cpSettings.name = this.model.name;
      this.fractalService.createControlPointsSettings(cpSettings).subscribe(res => {
        this.notifyService.showSuccess(res);
        this.getGpsSettings();
      });
    } else {
      var arr = getControlPointsSettingsFromRangeWithPrice(this.model.beginCp, this.model.endCp, this.model.major, this.model.punchAlgorithmId, this.model.defaultPrice);
      var toAdd = 1;
      if (this.model.startCpUsed) {
        toAdd = toAdd++;
      }
      var finishCp = getControlPointsSettingsFromRangeWithPrice(this.model.finishCpMinor, this.model.finishCpMinor, this.model.major, this.model.punchAlgorithmId, 0);
      var newArr = arr.concat(finishCp);
      if (this.model.startCpUsed) {
        var fa = getControlPointsSettingsFromRangeWithPrice(241, 241, this.model.major, this.model.punchAlgorithmId, 0);
        var d = newArr.concat(fa);
        newArr = d;
      }
      var cpSettings = new ControlPointsSettings();
      cpSettings.id = null
      cpSettings.allowedControlPoints = newArr;
      cpSettings.name = this.model.name;
      this.fractalService.createControlPointsSettings(cpSettings).subscribe(res => {
        this.notifyService.showSuccess(res);
        this.getGpsSettings();
      });
      console.log(newArr);
    }

  }

  showOrHideUpdateDialog() {
    this.isUpdateEventEnabled = !this.isUpdateEventEnabled;
  }

  open(content) {
    this.modalService.open(content,
      { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.createGpsSettings();
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult =
          `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    console.log(reason);
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  save() {
    console.log(this.model);
    // this.fractalService.createGpsSettings(this.model).subscribe(res => {
    //   console.log(res);
    // });
  }

}
