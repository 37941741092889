<div class="div-center">
  <button class="button-black" (click)="open(content)">Создать GPS настройки</button>
</div>
<div class="heroes">
  <li *ngFor="let settings of gpsSettings">
    <p>{{settings.name}}</p>
    <a>
      <p>{{settings.id}}</p>
      <p>Дистанция: {{settings.distance}}</p>
      <p>Точность: {{settings.accuracy}}</p>
      <p>Время: {{settings.time}}</p>
      <p>Период загрузки: {{settings.maxUploadSize}}</p>
    </a>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      Создание GPS настроек
    </h1>

    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="name">Название</label>
        <input type="text" class="form-control" id="name" [(ngModel)]="model.name" name="name" placeholder="По умолчанию (оптимально)">
      </div>

      <div class="form-group">
        <label for="uploadPeriod">Период загрузки</label>
        <input type="text" class="form-control" id="uploadPeriod" [(ngModel)]="model.uploadPeriod" name="uploadPeriod" placeholder="Интервал в секундах">
      </div>

      <div class="form-group">
        <label for="maxUploadSize">Максимальный размер</label>
        <input type="text" class="form-control" id="maxUploadSize" [(ngModel)]="model.maxUploadSize" placeholder="Количество байт"
          name="maxUploadSize" #name="ngModel">
      </div>

      <div class="form-group">
        <label for="accuracy">Точность</label>
        <input type="text" class="form-control" id="accuracy" [(ngModel)]="model.accuracy" name="accuracy" placeholder="Точность в метрах">
      </div>

      <div class="form-group">
        <label for="distance">Дистанция</label>
        <input type="text" class="form-control" id="distance" [(ngModel)]="model.distance" name="distance" placeholder="Дистанция в метрах"
          #name="ngModel">
      </div>

      <div class="form-group">
        <label for="time">Время</label>
        <input type="text" class="form-control" id="time" [(ngModel)]="model.time" name="time" #name="ngModel" placeholder="Время в миллисекундах">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
      Save
    </button>
  </div>
</ng-template>
