<div class="div-center">
  <div id="map"></div>
  <input type="text" class="form-control" style="margin: 20px;" id="cpId"
         [(ngModel)]="cpId"
         name="cpId" placeholder="">
  <button id="refreshButton" class="btn btn-success mr-1" style="margin: 20px;" (click)="showCpSettings()">Показать
    точки
  </button>

  <div>
    <h5>{{device?.id}}</h5>
    <div class="form-group form-check">
      <input type="checkbox" class="form-check-input" id="published" [(ngModel)]="device.locked" (click)="lockOrUnlock()" name="published">
      <h5 class="form-check-label" for="published">Запретить запись</h5>
    </div>
    <div>
      <h5>Дата создания: {{device?.createdDate}}</h5>
    </div>
    <h5>Мнимый идентификатор: {{device?.imaginaryId}}</h5>
    <h5>Последняя активность: {{device?.lastActiveTime}}</h5>
    <h5>Версия приложения: {{device?.deviceInfo?.appVersion}}</h5>
    <h5>Производитель: {{device?.deviceInfo?.manufacturer}}</h5>
    <h5>Модель: {{device?.deviceInfo?.model}}</h5>
    <h5>Версия Android: {{device?.deviceInfo?.androidVersion}}</h5>
    <button class="btn btn-success mr-1" (click)="open(content)">Добавить</button>
    <div style="margin: 20px;">
      <button class="btn btn-success mr-1" (click)="open2(content2)">Добавить отметки (new)</button>
    </div>
    <input type="text" class="form-control" style="margin: 20px;" id="deviceIdForCopy" [(ngModel)]="deviceIdForCopy"
           name="deviceIdForCopy" placeholder="">
    <button class="btn btn-success mr-1" style="margin: 20px;" (click)="copy()">Скопировать на устройство</button>

    <input type="text" class="form-control" style="margin: 20px;" id="participantForLink"
           [(ngModel)]="participantForLink"
           name="participantForLink" placeholder="">
    <button class="btn btn-success mr-1" style="margin: 20px;" (click)="link()">Привязать к участнику</button>
    <button class="btn btn-success mr-1" style="margin: 20px;" (click)="downloadGpx()">Скачать gpx</button>
  </div>
  <!-- <div class="row">
    <div class="column">
      <div class="div-box">
        <div *ngFor="let p of device?.punchData?.punches">
          <div class="div-box-black">
            <p class="p-base">{{p?.uuid}}</p>
            <p class="p-base">{{p?.major}}</p>
            <p class="p-base">{{p?.minor}}</p>
            <p class="p-base">{{p?.timestamp}}</p>
            <p class="p-base">{{p?.isDuplicate}}</p>
            <div class="div-center">
              <button class="btn btn-danger" (click)="delete(device,p)">Удалить</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="div-box">
        <div *ngFor="let p of device?.punchData?.deletedPunches">
          <div class="div-box-black">
            <p class="p-base">{{p?.uuid}}</p>
            <p class="p-base">{{p?.major}}</p>
            <p class="p-base">{{p?.minor}}</p>
            <p class="p-base">{{p?.timestamp}}</p>
            <p class="p-base">{{p?.isDuplicate}}</p>
            <div class="div-center">
              <button class="btn btn-success" (click)="restore(device,p)">Восстановить</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->


  <div>
    <!-- <p id="{{p.groupName}}" class="table-header">{{p.groupName}}</p> -->
    <div>
      <table class="table">
        <thead>
        <tr>
          <th>#</th>
          <th>uuid</th>
          <th>major</th>
          <th>minor</th>
          <th>timestamp</th>
          <th>packetId</th>
          <th>isDuplicate</th>
          <th>actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let p of device?.punchData?.punches" class="div-center">
          <td>{{device?.punchData?.punches?.indexOf(p) + 1}}</td>
          <td>
            <div class="device-details">
              <p class="p-base">{{p.uuid}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{p.major}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{p.minor}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{p.timestamp}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{p.packetId}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{p.isDuplicate}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <button (click)="delete(device,p)" class="btn btn-danger">Удалить</button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table class="table">
        <thead>
        <tr>
          <th>#</th>
          <th>uuid</th>
          <th>major</th>
          <th>minor</th>
          <th>timestamp</th>
          <th>packetId</th>
          <th>isDuplicate</th>
          <th>actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let p of device?.punchData?.deletedPunches" class="div-center">
          <td>{{device?.punchData?.deletedPunches?.indexOf(p) + 1}}</td>
          <td>
            <div class="device-details">
              <p class="p-base">{{p.uuid}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{p.major}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{p.minor}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{p.timestamp}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{p.packetId}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{p.isDuplicate}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <button class="btn btn-success" (click)="restore(device,p)">Восстановить</button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      Добавление отметки
    </h1>

    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="name">Major</label>
        <input type="text" class="form-control" id="name" [(ngModel)]="punch.major" name="major" placeholder="52671">
      </div>

      <div class="form-group">
        <label for="uploadPeriod">Minor</label>
        <input type="text" class="form-control" id="uploadPeriod" [(ngModel)]="punch.minors" name="minor"
               placeholder="33">
      </div>

      <div class="form-group">
        <label for="maxUploadSize">TAG</label>
        <input type="text" class="form-control" id="maxUploadSize" [(ngModel)]="punch.packetId" placeholder="admin-add"
               name="maxUploadSize" #name="ngModel">
      </div>

      <div class="form-group">
        <label for="accuracy">Время</label>
        <input type="text" class="form-control" id="accuracy" [(ngModel)]="punch.timestamp" name="accuracy"
               placeholder="2021-09-18T05:48:22.728Z">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
      Save
    </button>
  </div>
</ng-template>

<ng-template #content2 let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      Добавление отметки (new)
    </h1>

    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="uploadPeriod">Minor</label>
        <input type="text" class="form-control" id="uploadPeriod" [(ngModel)]="punch.minors" name="minor"
               placeholder="33">
      </div>

      <div class="form-group">
        <label for="accuracy">Время</label>
        <input type="text" class="form-control" id="accuracy" [(ngModel)]="punch.timestamp" name="accuracy"
               placeholder="2021-09-18T05:48:22.728Z">
      </div>
      <div class="form-group">
        <label for="cpId">Control Points id</label>
        <input type="text" class="form-control" id="cpId" [(ngModel)]="controlPointsSettings.id"
               name="cpId"
               placeholder="212131213"/>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
      Save
    </button>
  </div>
</ng-template>
