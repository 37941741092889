<div>
  <button class="button" (click)="open(content)">Создать контрольные точки</button>
</div>
<div class="heroes">
  <li *ngFor="let controlPointsSettings of controlPointsSettings">
<!--    <p>{{controlPointsSettings.name}}</p>-->
    <a routerLink="/settings/control-points/{{controlPointsSettings.id}}">
      <p>{{controlPointsSettings.id}}</p>
      <p>{{controlPointsSettings.name}}</p>
    </a>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      Создание контрольных точек
    </h1>

    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="name">Название</label>
        <input type="text" class="form-control" id="name" [(ngModel)]="model.name" name="name" placeholder="Золото Сенежа Вода (30-79)">
      </div>

      <div class="form-group">
        <label for="uploadPeriod">Номер первого КП</label>
        <input type="text" class="form-control" id="uploadPeriod" [(ngModel)]="model.beginCp" name="uploadPeriod" placeholder="Номер первого КП">
      </div>

      <div class="form-group">
        <label for="maxUploadSize">Номер последнего КП</label>
        <input type="text" class="form-control" id="maxUploadSize" [(ngModel)]="model.endCp" placeholder="Номер последнего КП"
          name="maxUploadSize" #name="ngModel">
      </div>
      <div class="form-group">
        <label for="punchAlgorithmId">ID алгоритма отметки </label>
        <input type="text" class="form-control" id="punchAlgorithmId" [(ngModel)]="model.punchAlgorithmId" placeholder="123123123"
          name="punchAlgorithmId" #name="ngModel">
      </div>

      <div class="form-group">
        <label for="major">Major </label>
        <input type="text" class="form-control" id="major" [(ngModel)]="model.major" placeholder="52671"
          name="major" #name="ngModel">
      </div>

      <div class="form-group form-check">
        <div>
          <input type="checkbox" class="form-check-input" id="startCpUsed" [(ngModel)]="model.startCpUsed" name="startCpUsed">
          <label class="form-check-label" for="startCpUsed">Стартовое КП (241)</label>
        </div>
      </div>

      <div class="form-group">
        <div>
          <label class="form-check-label" for="finishCpMinor">Финишное КП (minor)</label>
          <input type="text" class="form-control" id="finishCpMinor" [(ngModel)]="model.finishCpMinor" placeholder="240"
          name="finishCpMinor" #name="ngModel">
        </div>
      </div>
      <div class="form-group">
        <div>
          <label class="form-check-label" for="defaultPrice">Цена по умолчанию</label>
          <input type="text" class="form-control" id="defaultPrice" [(ngModel)]="model.defaultPrice" placeholder="-1"
          name="defaultPrice" #name="ngModel">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
      Save
    </button>
  </div>
</ng-template>
