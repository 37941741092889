import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Event, EventDay} from 'src/app/domain/models';
import {FractalService} from 'src/app/services/fractal/fractal.service';
import {Title} from '@angular/platform-browser';
import {NotificationService} from "../../services/notifications/notification.service";

@Component({
  selector: 'app-event-days',
  templateUrl: './event-days.component.html'
})
export class EventDaysComponent implements OnInit {

  model: Event;
  modelNew: EventDay = new EventDay();
  event: Event;
  days: EventDay[];
  isUpdateEventEnabled = false;
  closeResult = '';

  constructor(
    private fractalService: FractalService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private titleService: Title,
    private notifyService : NotificationService
  ) {
    titleService?.setTitle('Этапы');
  }

  ngOnInit() {
    const id: string = this.route.snapshot.params.id;
    this.getEvent(id);
    this.getEventDays(id);
  }

  getEvent(eventId: string) {
    this.fractalService.getEvent(eventId)
      .subscribe(heroes => {
          this.model = heroes;
          this.event = heroes;
          this?.titleService?.setTitle(heroes.name + ' - этапы');
        }
      );
  }

  getEventDays(eventId: string) {
    this.fractalService.getEventDays(eventId)
      .subscribe(heroes => {
          this.days = heroes;
        }
      );
  }

  save() {
    console.log(this.model);
    this.fractalService.updateEvent(this.model).subscribe(res => {
      this.notifyService.showSuccess("Обновили")
    });
  }

  create() {
    console.log(this.modelNew);
    this.fractalService.createEventDay(this.event.id, this.modelNew).subscribe(res => {
      this.notifyService.showSuccess(res);
    });
  }

  open(content) {
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.save();
      console.log(this.model);
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult =
        `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    console.log(reason);
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openCreateDialog(content) {
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title2'}).result.then((result) => {
      this.create();
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult =
        `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
