import {Component, OnInit} from '@angular/core';
import {
  AllowedControlPoint,
  AnalyzedGpsTrack,
  AnalyzedGpsTracksData,
  AnalyzeParameters,
  ControlPointsSettings,
  EventDay,
  EventResults,
  GpsPoint,
  Location,
  Punch,
  PunchStatus
} from 'src/app/domain/models';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ViewportScroller} from '@angular/common';
import {Title} from "@angular/platform-browser";
import {FractalService} from "../../services/fractal/fractal.service";
import {getSortedGroups, parseResults, sortResults, sortTeams2} from "../../domain/data-utls";
import {EventResultsTableComponent} from '../event-results-table/event-results-table.component';
import * as L from 'leaflet';
import {NotificationService} from "../../services/notifications/notification.service";

@Component({
  selector: 'app-analyze-gps-tracks',
  templateUrl: './analyze-gps-tracks.component.html',
  styleUrls: ['./analyze-gps-tracks.component.scss']
})
export class AnalyzeGpsTracksComponent implements OnInit {

  results: EventResults;
  data: AnalyzedGpsTracksData = new AnalyzedGpsTracksData();
  sortedGroups: string[];
  currentEvent: EventDay;
  isLoading: boolean = true;
  isMapShowed: boolean = true;
  analyzeParameters: AnalyzeParameters;
  static map;


  constructor(
    private actRoute: ActivatedRoute,
    private api: FractalService,
    private modalService: NgbModal,
    private scroller: ViewportScroller,
    private router: Router,
    private titleService: Title,
    private notifyService: NotificationService,
  ) {
    this.titleService.setTitle("Анализ треков");
  }

  ngOnInit(): void {
    this.analyzeParameters = new AnalyzeParameters();
    this.analyzeParameters.accuracy = 0.035;
    this.reloadAll()
  }

  reloadAll() {
    //TODO add loading gps tracks
    //TODO add loading control points
    this.loadResults()
  }

  loadResults() {
    this.api.getResultsNew(this.actRoute.snapshot.params.id1, this.actRoute.snapshot.params.id2).subscribe((res) => {
      EventResultsTableComponent.cpSettings = res.controlPointsSettings
      let newResult = parseResults(res)
      newResult = sortResults(newResult)
      this.sortedGroups = getSortedGroups(newResult)
      newResult = sortTeams2(newResult, "rogaine")
      this.results = newResult
      this.initMap(this.results.controlPointsSettings[0]?.allowedControlPoints[0]?.location);
      this.addMarkersForControlPoints(this.results.controlPointsSettings[0])
      // this.data = analyzePunches(newResult.participants); //TODO convert list to analyzed points
      this.loadGpsTracks()

    })
    this.api.GetDay(this.actRoute.snapshot.params.id, this.actRoute.snapshot.params.id2).subscribe((res) => {
      this.currentEvent = res;
    })
  }

  gpsPointsMap: Map<string, GpsPoint[]> = new Map();

  loadGpsTracks() {
    var groupBy = function (xs) {
      return xs.reduce(function (r, a) {
        r[a.deviceId] = r[a.deviceId] || [];
        this.gpsPointsMap[a.deviceId]?.push(a)
        r[a.deviceId].push(a);
        return r;
      }, Object.create(null));
    };
    let ids = []
    this.results.participants?.forEach(x => {
      x.teams.forEach(t => {
        t.runners.forEach(r => {
          ids.push(r?.device?.id)
        })
      })
    })
    this.api.getGpsPoints(ids).subscribe((res) => {
      res.forEach(x => {
        if (!this.gpsPointsMap[x.deviceId]) {
          this.gpsPointsMap[x.deviceId] = []
        }
        this.gpsPointsMap[x.deviceId].push(x);
      })
      ids.forEach(id => {
        this.results.participants?.forEach((x, xi) => {
          x.teams.forEach((t, ti) => {
            t.runners.forEach((r, ri) => {
              // var index = this.data.tracks.findIndex(x => x.participant.id == r.id)
              // var track = this.data.tracks.find(x => x.participant.id == r.id)
              if (id == r?.device?.id) {
                this.results.participants[xi].teams[ti].runners[ri].gpsPoints = this.gpsPointsMap[id?.toString()]
                this.results.participants[xi].teams[ti].runners[ri].gpsPoints = this.gpsPointsMap[id?.toString()]
              }
              try {
                // this.addTrackToMap(this.map[id?.toString()])
              } catch (e) {

              }
            })
          })
        })
      })
      this.isLoading = false
      this.analyze()
    })
  }

  addTrackToMap(gpsPoints: GpsPoint[]) {
    let points = []
    let colors = []
    gpsPoints?.sort((a, b) => {
      if (a.timestamp > b.timestamp) return 1;
      if (a.timestamp < b.timestamp) return -1;
      return 0;
    }).forEach(x => {
        var color = "";
        if (x.speed < 2) {
          color = "#fc2c03"
        } else if (x.speed > 2 && x.speed < 3) {
          color = "#fcdb03"
        } else {
          color = "#39fc03"
        }
        colors.push(color)
        points.push({lat: x.latitude, lng: x.longitude, z: color})
      }
    )
    ;

    //Define an array of Latlng objects (points along the line)
    var polylineOptions = {
      color: 'blue',
      weight: 6,
      opacity: 0.9,
      smoothFactor: 1,
    };

    var polyline = new L.Polyline(points, polylineOptions);
    this.setPolylineColors(polyline, colors);
    // AnalyzeGpsTracksComponent.map.off();
    // AnalyzeGpsTracksComponent.map.remove();
    // this.initMap(this.results.controlPointsSettings[0]?.allowedControlPoints[0]?.location);
    // AnalyzeGpsTracksComponent.map.addLayer(polyline);
  }

  // Converts numeric degrees to radians
  toRad(Value) {
    return Value * Math.PI / 180;
  }

  //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
  calcCrow(_lat1, _lon1, _lat2, _lon2) {
    var R = 6371; // km
    var dLat = this.toRad(_lat2 - _lat1);
    var dLon = this.toRad(_lon2 - _lon1);
    var lat1 = this.toRad(_lat1);
    var lat2 = this.toRad(_lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  markerOnClick(e) {
    alert("hi. you clicked the marker at " + e.latlng);
  }

  onMarkerClick(e: Location) {
    console.log("ON MARKER CLICK")
    alert(e.longitude + "," + e.latitude);
  }

  analyze() {
    this.data.tracks = []

    var allowedControlPoints = this.results.controlPointsSettings[0].allowedControlPoints
    //TODO check existing punches
    this.results?.participants?.forEach(p => {
      p.teams?.forEach(t => {
        t.runners?.forEach(r => {
          var punchesToCheck: AllowedControlPoint[] = [];
          console.log(r?.device?.punchData)
          allowedControlPoints.forEach(al => {
            if ((r?.device?.punchData?.punches?.find(e => e.minor === al.settings.minor) == null) && r?.device?.punchData?.deletedPunches?.find(e => e.minor === al.settings.minor) == null) {
              // console.log("PUNCH TO ADD " + JSON.stringify(al))
              punchesToCheck.push(al)
            }
          })
          console.log(punchesToCheck)
          var waitingApprove: Punch[] = []
          r?.gpsPoints?.forEach(gpsPoint => {
            punchesToCheck.forEach(toCheck => {
              let nearestDistance = this.analyzeParameters.accuracy
              var calculatedDistance = this.calcCrow(toCheck.location.latitude, toCheck.location.longitude, gpsPoint.latitude, gpsPoint.longitude)
              if (calculatedDistance < nearestDistance) {
                console.log(calculatedDistance)
                nearestDistance = calculatedDistance
              }
              if (this.analyzeParameters.accuracy >= calculatedDistance) {
                toCheck.nearestDistance = nearestDistance
                var toAdd = new Punch();
                toAdd.uuid = toCheck.settings.uuid
                toAdd.major = toCheck.settings.major
                toAdd.minor = toCheck.settings.minor
                toAdd.timestamp = new Date(gpsPoint.timestamp).toISOString()
                toAdd.location = new Location();
                toAdd.location.longitude = gpsPoint.longitude
                toAdd.location.latitude = gpsPoint.latitude
                toAdd.location.accuracy = gpsPoint.accuracy
                toAdd.packetId = "gps-" + gpsPoint.id
                toCheck.status = PunchStatus.waiting_approve
                var found = waitingApprove.find(x => x.major == toAdd.major && x.minor == toAdd.minor)
                if (found == null) {
                  waitingApprove.push(toAdd)
                } else {
                  if (found.nearestDistance > toAdd.nearestDistance) {
                    waitingApprove = waitingApprove.filter(x => x.timestamp == found.timestamp)
                    waitingApprove.push(toAdd)
                  }
                }
                //TODO check if already exists with biggest distance - replace item
              }
              //TODO compare nearestDistance with accuracy parameter / distance parameter
              //TODO add to ok or not
            })
            // allowedControlPoints.forEach(alcp => {

            //TODO check distance between points
            //TODO find ok punches
            //TODO find error punches
            // })
            // r.device?.punchData?.punches?.forEach(punch => {
            //
            // })
          })
          r.newDevice.toCheck = punchesToCheck
          var track = new AnalyzedGpsTrack();
          track.gpsTrack = r?.gpsPoints
          if (track.gpsTrack == null) track.gpsTrack = []
          track.participant = r
          track.waitingApprovePunches = waitingApprove
          this.data.tracks.push(track)
          //TODO for each punches
          //TODO get punch location from control points settings
          //TODO get nearest location from gps data
          //TODO add to ok or waiting for approve
        })
      })
    })
    this.data.tracks = this.data.tracks.sort((a, b) => {
      if (a.waitingApprovePunches.length < b.waitingApprovePunches.length) {
        return 1;
      }
      if (a.waitingApprovePunches.length > b.waitingApprovePunches.length) {
        return -1;
      }
      return 0;
    });
  }

  addMarkersForControlPoints(controlPoints: ControlPointsSettings) {
    controlPoints?.allowedControlPoints?.forEach(x => {
      // Icon options
      var iconOptions = {
        iconUrl: 'src/assets/cp.png',
        iconSize: [50, 50]
      }
      // Creating a custom icon
      var customIcon = L.icon(iconOptions);
      L.marker([x?.location?.latitude, x?.location?.longitude], {
        icon: L.divIcon({
          html: x.name,
          className: 'text-below-marker',
        })
      })?.addTo(AnalyzeGpsTracksComponent.map)
      // Creating Marker Options
      var markerOptions = {
        title: x.name,
        clickable: false,
        draggable: false,
        // icon: customIcon
      }
      var marker = new L.Marker([x?.location?.latitude, x?.location?.longitude], markerOptions)
      marker.addTo(AnalyzeGpsTracksComponent.map)
    })
  }

  showOnMap(data: AnalyzedGpsTrack) {
    this.addTrackToMap(data.gpsTrack)
  }

  analyzeControlPoints() {

  }

  analyzeGpsTracks() {

  }

  approve(punch: Punch, data: AnalyzedGpsTrack) {
    this.api.uploadPunch(data.participant?.device?.id, [punch]).subscribe(res => {
      res.success.forEach(x =>
        this.notifyService.showSuccess(x.message + ' - ' + x.status)
      );
      res.errors.forEach(x =>
        this.notifyService.showError(x.message + ' - ' + x.minor)
      )
    });
    // this.notifyService.showInfo(JSON.stringify(punch))
  }

  switchMap() {
    this.isMapShowed = !this.isMapShowed
    // if (this.isMapShowed) this.initMap();
  }

  ngAfterViewInit(): void {
  }

  private initMap(initCoords: Location): void {
    var map = L.map('map').setView([initCoords.latitude, initCoords.longitude], 13);
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);
    AnalyzeGpsTracksComponent.map = map;
  }

  setPolylineColors(line, colors) {

    var latlngs = line.getLatLngs();

    latlngs.forEach(function (latlng, idx) {
      if (idx + 1 < latlngs.length) {
        var poly = L.polyline([latlng, latlngs[idx + 1]], {color: colors[idx]}).addTo(AnalyzeGpsTracksComponent.map);
        AnalyzeGpsTracksComponent.map.addLayer(poly);
      }
    })
    // AnalyzeGpsTracksComponent.map.fitBounds(line.getBounds());
  }

  addMarkers(markers: Location[], colors) {
    markers.forEach(x => {
      var marker = new L.Marker([x.latitude, x.longitude])
      marker.on('click', function () {
        console.log("CLICK")
      })
      marker.addTo(AnalyzeGpsTracksComponent.map)
    })
  }

}
