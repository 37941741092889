<div class="container">
  <h1>Изменение формата</h1>
  <div class="cb">
    <button type="button-main" class="btn btn-success" (click)="open(content)">Обновить формат</button>
  </div>

  <div>
    <button class="button-main" (click)="openCreateDialog(content2)">Создать группу</button>
  </div>

  <h1 class="title" style="font-style: bold; text-align: center;">Группы</h1>
  <!-- <div class="heroes">
    <li *ngFor="let group of groups">
      <a routerLink="groups/{{group.id}}">
        <span>{{group.name}}</span>
        <div>
          <p>{{group.controlStartTime}}</p>
          <p>{{group.controlFinishTime}}</p>
        </div>
      </a>
  </div> -->
  <div style="margin: 20px;">
    <table class="table">
      <thead>
        <tr>
          <th>id</th>
          <th>name</th>
          <th>start time</th>
          <th>finish time</th>
          <th>actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let obj of groups" class="div-center">
          <td>
            <div class="device-details">
              <p class="p-base">{{obj.id}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{obj.name}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{obj.controlStartTime}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{obj.controlFinishTime}}</p>
            </div>
          </td>
          <td>
            <div>
              <a class="button-black" routerLink="groups/{{obj.id}}" target="_blank">Детали</a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #content2 let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      Создание группы
    </h1>

    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="name">Название</label>
        <input type="text" class="form-control" id="name" required [(ngModel)]="newGroupModel.name" name="name"
          #name="ngModel">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Обязательное поле
        </div>
      </div>
      <div class="form-group">
        <label for="value">Значение</label>
        <input type="text" class="form-control" id="value" required [(ngModel)]="newGroupModel.value" name="value"
          #name="ngModel">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Обязательное поле
        </div>
      </div>

      <div class="form-group">
        <label for="startTime">Время старта</label>
        <input type="text" class="form-control" id="startTime" required [(ngModel)]="newGroupModel.controlStartTime"
          name="startTime" #name="ngModel">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Обязательное поле
        </div>
      </div>

      <div class="form-group">
        <label for="minAge">Минимальный возраст</label>
        <input type="text" class="form-control" id="minAge" required [(ngModel)]="newGroupModel.minAge" name="minAge"
          #name="ngModel">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Обязательное поле
        </div>
      </div>
      <div class="form-group">
        <label for="maxAge">Максимальный возраст</label>
        <input type="text" class="form-control" id="maxAge" required [(ngModel)]="newGroupModel.maxAge" name="maxAge"
          #name="ngModel">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Обязательное поле
        </div>
      </div>

      <!-- <div class="form-group">
        <label for="controlTime">Контрольное время (В секундах)</label>
        <input type="text" class="form-control" id="controlTime" required [(ngModel)]="newGroupModel.controlTime" name="controlTime" #name="ngModel">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Обязательное поле
        </div>
      </div> -->
      <div class="form-group">
        <label for="punch-algorithm">Настройки контрольных точек</label>
        <select class="form-control" id="gps-settings" [(ngModel)]="selectionalControlPointsSettings"
          name="gps-settings" ng-model="selectionalControlPointsSettings" ng-options="x for x in controlPointsSettings">
          <option *ngFor="let c of controlPointsSettings" [ngValue]="c">{{c.name}}</option>
        </select>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="button-main" (click)="modal.close('Save click')">
      Save
    </button>
  </div>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      Обновление формата
    </h1>

    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="name">Название</label>
        <input type="text" class="form-control" id="name" [(ngModel)]="model.name" name="name" placeholder="5555">
      </div>
      <div class="form-group">
        <input type="checkbox" class="form-check-input" id="published" [(ngModel)]="model.autoRegisterInGroups"
          name="published">
        <label class="form-check-label" for="autoRegisterInGroups">Автоматическая регистрация в группы</label>
      </div>

      <div class="form-group">
        <label for="punch-algorithm">Алгоритм отметки ({{selectedAlgorithm?.name}})</label>
        <select class="form-control" id="punch-algorithm" [(ngModel)]="selectedAlgorithm" name="punch-algorithm"
          ng-model="selectedAlgorithm" ng-options="x for x in punchAlgorithms">
          <option *ngFor="let c of punchAlgorithms" [ngValue]="c">{{c.name}}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="gps-settings">Gps настройки ({{selectedGpsSettings?.name}})</label>
        <select class="form-control" id="gps-settings" [(ngModel)]="selectedGpsSettings" name="gps-settings"
          ng-model="selectedGpsSettings" ng-options="x for x in gpsSettings">
          <option *ngFor="let c of gpsSettings" [ngValue]="c">{{c.name}}</option>
        </select>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="button-main" (click)="modal.close('Save click')">
      Save
    </button>
  </div>
</ng-template>