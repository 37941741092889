<div class="content">

  <div style="display: inline;">
    <h3>Participant id</h3>
    <input type="text" class="form__field" id="majoldTeamIdor" [(ngModel)]="participantId" placeholder="" name="oldTeamId"
      #name="ngModel">
  </div>

  <div style="display: inline;">
    <h3>Old team id</h3>
    <select class="form-control" id="group"
    [(ngModel)]="modelTeam.oldTeamId"
    name="currentGroup" ng-model="currentGroup" ng-options="x for x in groups">
    <option *ngFor="let c of teamsAndGroups.teams" [ngValue]="c.id">{{c.bib}} {{c.name}}</option>
  </select>
  </div>

  <div style="display: inline;">
    <h3>New team id</h3>
      <select class="form-control" id="group"
      [(ngModel)]="modelTeam.newTeamId"
      name="currentGroup" ng-model="currentGroup" ng-options="x for x in groups">
      <option *ngFor="let c of teamsAndGroups.teams" [ngValue]="c.id">{{c.bib}} {{c.name}} {{c.id}}</option>
    </select>
    <button class="button-main" (click)="updateTeam()">Save team</button>
  </div>

  <div style="display: inline;">
    <h3>Team id</h3>
    <input type="text" class="form__field" id="major" [(ngModel)]="modelGroup.teamId" placeholder="" name="oldGroupId"
      #name="ngModel">
  </div>

  <div style="display: inline;">
    <h3>Old group id</h3>
    <select class="form-control" id="group"
      [(ngModel)]="modelGroup.oldGroupId"
      name="currentGroup" ng-model="currentGroup" ng-options="x for x in groups">
      <option *ngFor="let c of teamsAndGroups.groups" [ngValue]="c.id">{{c.name}}</option>
    </select>
  </div>

  <div style="display: inline;">
    <h3>New group id</h3>
    <select class="form-control" id="group"
      [(ngModel)]="modelGroup.newGroupId"
      name="currentGroup" ng-model="currentGroup" ng-options="x for x in groups">
      <option *ngFor="let c of teamsAndGroups.groups" [ngValue]="c.id">{{c.name}}</option>
    </select>
    <button class="button-main" (click)="updateGroup()">Save group</button>
  </div>
</div>
