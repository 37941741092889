import {Event} from 'src/app/domain/models';

export function checkModel(event: Event) {
  if (event.name === null) {
    return false;
  }
  if (event.description === null) {
    return false;
  }
  if (event.beginDate === null) {
    return false;
  }
  if (event.endDate === null) {
    return false;
  }
  if (event.published === null) {
    return false;
  }
  return event.closed !== null;

}

export function arrayDiff (a1, a2) {
  var a = [], diff = [];
  for (var i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }
  for (var i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }
  for (var k in a) {
    diff.push(k);
  }
  return diff;
}
