import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Group, Team, TeamsAndGroups, UpdateLinks} from 'src/app/domain/models';
import {FractalService} from 'src/app/services/fractal/fractal.service';
import {ModelGroup} from '../participants-manage/participants-manage.component';
import {NotificationService} from "../../services/notifications/notification.service";

@Component({
  selector: 'app-manage-links',
  templateUrl: './manage-links.component.html',
  styleUrls: ['./manage-links.component.scss']
})
export class ManageLinksComponent implements OnInit {

  modelGroup: ModelGroup = new ModelGroup();
  groups: Group[];
  teamsAndGroups: TeamsAndGroups;
  teams: Team[];
  eventId: string = '';
  dayId: string = '';

  constructor(
    private route: ActivatedRoute,
    private api: FractalService,
    private notifyService : NotificationService
  ) {
    this.eventId = this.route.snapshot.params.id1;
    this.dayId = this.route.snapshot.params.id2;
  }

  ngOnInit(): void {
    this.api.getTeamsAndGroups(this.eventId, this.dayId).subscribe(g => {
      this.teamsAndGroups = g;
    });
  }

  link() {
    this.notifyService.showInfo("Привязываем...");
    this.api.linkTeamToOtherGroupSimple(this.eventId, this.dayId, this.modelGroup.teamId, new UpdateLinks(this.modelGroup.oldGroupId, '')).subscribe(res => {
      this.notifyService.showSuccess(res);
    });
  }

  unlink() {
    this.notifyService.showInfo("Отвязываем...");
    this.api.unlinkTeamToOtherGroupSimple(this.eventId, this.dayId, this.modelGroup.teamId, new UpdateLinks(this.modelGroup.oldGroupId, '')).subscribe(res => {
      this.notifyService.showSuccess(res);
    });
  }

}
