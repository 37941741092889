import {NewDevice} from './result-utils';
import {KalmanFilter} from "./filter-utils";

// Teams
export class LocalTeam {
  name: string;
  bib: string;
  groups: string[];
  runners: Participant[];
}

// Other
export class ExtendControlPoint {
  name: string;
  uuid: string;
  code: number;
  major: number;
  timestamp: string;
  description: string;
  location: Location;
  price: number;
  isDuplicate: boolean;
  tag: string;
}

// Events

export class Event {
  id: string;
  name: string;
  description: string;
  createdDate: string;
  beginDate: string;
  endDate: string;
  location: Location;
  password: string;
  published: boolean;
  closed: boolean;
  registrationBeginDate: string;
}

export class EventDay {
  id: string;
  name: string;
  description: string;
  createdDate: string;
  beginDate: string;
  endDate: string;
  location: Location;
  password: string;
  published: boolean;
  closed: boolean;
  selfRegistration: boolean;
  registrationBeginDate: string;
  ogpsEventId: number;
  ogpsEventUrl: string;
  type: string;
  diploma: Diploma;
  qrEnabled: boolean;
}

export class Diploma {
  url: string;
  margins: Margins;
}

export class Margins {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

export class EventResults {
  participants: ParticipantInfo[];
  controlPointsSettings: ControlPointsSettings[];
}

export class EventResultsNew {
  teams: TeamLocal[];
  controlPointsSettings: ControlPointsSettings[];
}

// Format
export class Format {
  id: string;
  name: string;
  autoRegisterInGroups: boolean;
  gpsSettings: GpsSettings;
  punchAlgorithm: PunchAlgorithm;
}

// Settings
export class GpsSettings {
  id: string;
  name: string;
  uploadPeriod: number;
  maxUploadSize: number;
  distance: number;
  time: number;
  accuracy: number;
}

export class PunchAlgorithm {
  id: string;
  name: string;
  analyzeDelay: number;
  type: string;
}

export class ControlPointsSettings {
  id: string = "";
  name: string;
  createdAt: string;
  updatedAt: string;
  allowedControlPoints: AllowedControlPoint[] = [];
}

export class AllowedControlPoint {
  name: string;
  code: number;
  description: string;
  location: Location;
  price: number;
  settings: Settings;
  nearestDistance: number;
  status: PunchStatus;
  beginWorkingTime: string;
  endWorkingTime: string;
  qrHash: string;
}

export class Settings {
  uuid: string;
  major: number;
  minor: number;
  punchAlgorithmId: string;
}

// Groups
export class Group {
  id: string;
  name: string;
  value: string;
  controlStartTime: string;
  controlFinishTime: string;
  minAge: number;
  maxAge: number;
  genders: Genders;
  distances: Distance[];
  teams: Team[];
  controlTimeInSeconds: number;
}

export class Genders {
  femaleAllowed = true;
  maleAllowed = true;
  undefinedAllowed = true;
}

// Teams
export class Team {
  id: string;
  name: string;
  bib: string;
  participants: Participant[];
}

export class TeamLocal {
  name: string;
  bib: string;
  groups: string[];
  runners: ParticipantWithDeviceDto[];
  controlPointsSettingsId: string;
}

// Participants
export class Participant {
  id: string;
  bib: string;
  name: string;
  surname: string;
  patronymic: string;
  controlStartTime: string;
  controlFinishTime: string;
  birthDate: string;
  phone: string;
  email: string;
  reservePhone: string;
  gender: string;
  category: string;
  devices: Device[];
  distances: Distance[];
  status: string;
  comment: string;
}

export class ParticipantInfo {
  groupName: string;
  teams: TeamLocal[] = [];

  constructor(
    groupName: string,
    team: TeamLocal
  ) {
    this.groupName = groupName;
    this.teams.push(team);
  }
}

export class ParticipantWithDeviceDto {
  id: string;
  bib: string;
  name: string;
  surname: string;
  patronymic: string;
  controlStartTime: string;
  controlFinishTime: string;
  birthDate: string;
  phone: string;
  email: string;
  reservePhone: string;
  category: string;
  groupName: string;
  device: Device;
  newDevice: NewDevice;
  controlPointsSettingsId: string;
  gender: string;
  score: number;
  gpsPoints: GpsPoint[] = [];
}

// Distances
export class Distance {
  id: string;
  type: string;
  controlPointSettingsId: string;
  teams: Team[];
}

export class SelectionalDistance extends Distance {
  minCount: number;
  controlTimeInSeconds: number;
}

export class DirectionalDistance extends Distance {

}

export class MixedDistance extends Distance {

}

// Devices
export class Device {
  id = '';
  imaginaryId: string;
  systemVersion: string;
  systemSdkInt: string;
  deviceInfo: DeviceInfo = new DeviceInfo();
  createdDate: string;
  punchData: PunchData;
  gpsData: GpsData;
  deviceStateData: DeviceStateData;
  logsData: LogsData;
  lastActiveTime: string;
  locked: boolean = false;
}

export class DeviceInfo {
  manufacturer: string;
  model: string;
  systemVersion: string;
  appVersion: string;
  androidVersion: string;
}

export class DeviceStateData {
  id: string;
  batteryLevels: any[];
}

export class GpsData {
  id: string;
  public gpsPoints: GpsPoint[];
}

export class CheckingPunch {

}

export function groupBy<T>(arr: T[], fn: (item: T) => any) {
  return arr.reduce<Record<string, T[]>>((prev, curr) => {
    const groupKey = fn(curr);
    const group = prev[groupKey] || [];
    group.push(curr);
    return {...prev, [groupKey]: group};
  }, {});
}

export class GpsPoint {
  id: string;
  deviceId: string;
  timestamp: string;
  latitude: number;
  longitude: number;
  altitude: number;
  accuracy: number;
  bearing: number;
  provider: string;
  speed: number;
  localId: string;
}

export class DeviceIds {
  ids: String[];
}

export class LogsData {
  logs: any[];
}

export class PunchData {
  id: string;
  punches: Punch[];
  deletedPunches: Punch[];
}

export class Punch {
  uuid: string;
  major: number;
  minor: number;
  minors: string;
  timestamp: string;
  location: Location;
  packetId: string;
  isDuplicate: boolean;
  nearestDistance: number;
  status: PunchStatus;
}

export enum PunchStatus {
  ok, //When nearest distance is small then accuracy
  waiting_approve, //When nearest distance

}

export class Location {
  latitude: number;
  longitude: number;
  accuracy: number;
}

export class DeviceState {
  device: Device;
  participant: Participant;
  group: Group;
  team: Team;
}


export class UpdateLinks {
  entityId: string;
  newId: string;

  constructor(
    entityId: string,
    newId: string
  ) {
    this.entityId = entityId;
    this.newId = newId;
  }
}

export class TeamsAndGroups {
  teams: Team[];
  groups: Group[];
}

export class UploadPunches {
  success: UploadPunchSuccess[];
  errors: UploadPunchError[];
}

export class BaseReponse {
  message: string
}

export class UploadPunchSuccess {
  localPunchId: string;
  status: string;
  message: string;
  uuid: string;
  major: string;
  minor: string;
}

export class UploadPunchError {
  localPunchId: string;
  message: string;
  uuid: string;
  major: string;
  minor: string;
}

export enum ParticipantStatuses {
  UNKNOWN,
  NOT_REGISTERED,
  NOT_STARTED,
  STARTED,
  FINISHED
}

export class UploadCSV {
  controlPointsSettingsId: string = "142923409";
  punchAlgorithmId: string = "15269269";
  gpsSettingsId: string = "15269280";
  data: string;
}

export class AnalyzedPunchData {
  controlPoint: AnalyzedPunch[] = [];
  totalAddedAdmin: number = 0;
  totalAddedGps: number = 0;
  totalAddedQr: number = 0;
}

export class AnalyzedPunch {
  name: string;
  minor: string;
  allPunches: ExtendControlPoint[] = [];
  filteredPunches: ExtendControlPoint[] = [];
  adminAdded: ExtendControlPoint[] = [];
  gpsAdded: ExtendControlPoint[] = [];
  qrAdded: ExtendControlPoint[] = [];
  participants: ParticipantWithDeviceDto[] = [];
  expanded = false;
}

export class AnalyzedGpsTracksData {
  tracks: AnalyzedGpsTrack[] = [];
}

export class AnalyzedGpsTrack {
  participant: ParticipantWithDeviceDto;
  gpsTrack: GpsPoint[] = [];
  waitingApprovePunches: Punch[] = [];
  expanded = false;
}

export class AnalyzeParameters {
  accuracy: number;
}

export class EventInfo {
  rawData: EventResults;
  participants: ParticipantWithDeviceDto[];
  total: number = 0;
  finished: number = 0;
  notStarted: number = 0;
  beneficiary: number = 0;
  main: number = 0;
}

export class AppPreferences {
  lastControlPointsSettings: ControlPointsSettings = new ControlPointsSettings();
  authData: AuthData = new AuthData();
}

export class AuthData {
  username: string = "";
  password: string = "";
}

export class KalmanGPS {
  kalmanLat: KalmanFilter;
  kalmanLong: KalmanFilter;
}

export class UpdateControlPoints {
  data: string;
}

export class NewParticipant {
  bib: string = "";
  name: string = "";
  surname: string = "";
  group: string = "";
  childGroup: string = "";
  teamName: string = "";
  birthDate: string = "";
}
