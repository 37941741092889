<div class="div-center">
  <button class="button-black" (click)="open(content)">Создать новый алгоритм</button>
</div>
<div class="heroes">
  <li *ngFor="let algorithm of punchAlgorithms">
    <p>{{algorithm.name}}</p>
    <a>
      <p>{{algorithm.id}}</p>
      <p>Название: {{algorithm.name}}</p>
      <p>Задержка: {{algorithm.analyzeDelay}}</p>
    </a>
</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      Создание алгоритма
    </h1>
    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="name">Название</label>
        <input type="text" class="form-control" id="name" [(ngModel)]="model.name" name="name"
               placeholder="Алгоритм по умолчанию">
      </div>

      <div class="form-group">
        <label for="analyzeDelay">Задержка</label>
        <input type="text" class="form-control" id="analyzeDelay" [(ngModel)]="model.analyzeDelay" name="analyzeDelay"
               placeholder="Интервал в секундах">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
      Save
    </button>
  </div>
</ng-template>
