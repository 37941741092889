import {Component, OnInit} from '@angular/core';
import {PunchAlgorithm} from 'src/app/domain/models';
import {FractalService} from 'src/app/services/fractal/fractal.service';
import {NotificationService} from "../../services/notifications/notification.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-punch-algorithm',
  templateUrl: './punch-algorithm.component.html',
  styleUrls: ['./punch-algorithm.component.scss']
})
export class PunchAlgorithmComponent implements OnInit {

  punchAlgorithms: PunchAlgorithm[];
  model: PunchAlgorithm = new PunchAlgorithm();
  isUpdateEventEnabled = false;

  constructor(
    private fractalService: FractalService,
    private notifyService: NotificationService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.getGpsSettings();
  }

  getGpsSettings() {
    this.fractalService.getPunchAlgorithms().subscribe(res => {
      this.punchAlgorithms = res;
    })
  }

  showOrHideUpdateDialog() {
    this.isUpdateEventEnabled = !this.isUpdateEventEnabled
  }

  open(content) {
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.save();
    }, (reason) => {
    });
  }

  save() {
    console.log(this.model);
    this.fractalService.createPunchAlgorithm(this.model).subscribe(res => {
      this.notifyService.showSuccess(res);
    });
  }
}
