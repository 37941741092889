import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FractalService} from "../../services/fractal/fractal.service";
import {Title} from "@angular/platform-browser";
import {NotificationService} from "../../services/notifications/notification.service";
import {
  AllowedControlPoint,
  AppPreferences,
  ControlPointsSettings,
  Device,
  GpsPoint,
  Location,
  Punch,
  PunchStatus
} from "../../domain/models";
import {millisToTimeUtc, PATTERN_FULL_DATE, PATTERN_UTC, utcToLocal} from "../../domain/date-utils";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import * as L from 'leaflet';
import {Observable, Subscriber} from "rxjs";
import {PreferencesService} from "../../services/preferences/preferences.service";

@Component({
  selector: 'app-device-info',
  templateUrl: './device-info.component.html',
  styleUrls: ['./device-info.component.scss']
})
export class DeviceInfoComponent implements OnInit {

  device: Device = new Device();
  deviceId: string;
  deviceIdForCopy: string;
  participantForLink: string;
  punch: Punch = new Punch();
  cpSettingsId: string = "";
  controlPointsSettings: ControlPointsSettings = new ControlPointsSettings();
  cpId: string;
  mapCpSettings: ControlPointsSettings;

  static map;

  private getCurrentPosition(): any {
    return new Observable((observer: Subscriber<any>) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: any) => {
          observer.next({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          observer.complete();
        });
      } else {
        observer.error();
      }
    });
  }

  private initMap(): void {
    var map = L.map('map').setView([55.672930, 37.284517], 13);
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);
    DeviceInfoComponent.map = map;
  }

  parseRouteParams(params: string) {
    var splitted = params.split(";")
    this.deviceId = splitted[0];
    this.cpId = splitted[1]?.split('=')[1]
    this.showCpSettings()
    this.getDevice()
  }

  constructor(
    private route: ActivatedRoute,
    private api: FractalService,
    private titleService: Title,
    private modalService: NgbModal,
    private notifyService: NotificationService,
    private preferencesService: PreferencesService,
  ) {
    route.params.subscribe(params => {
        this.parseRouteParams(params.id.toString())
      }
    )
    this.punch.major = 3333;
    this.punch.minors = "";
    this.punch.uuid = "74278bda-b644-4520-8f0c-720eaf059935";
    this.punch.packetId = "admin-add";
    this.punch.timestamp = new Date().toISOString();
    this.controlPointsSettings = preferencesService?.appPreferences?.lastControlPointsSettings
    // this.punch.timestamp = new Date().toISOString();
    titleService?.setTitle('Управление устройством');

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  showCpSettings() {
    this.api.getControlPointsSettingsById(this.cpId).subscribe(res => {
      console.log("LOADED")
      this.controlPointsSettings = res
      this.mapCpSettings = res
      this.addMarkersForControlPoints(res)
    })
  }

  addMarkers(markers: Location[], colors) {
    markers.forEach(x => {
      var marker = new L.Marker([x.latitude, x.longitude])
      marker.addTo(DeviceInfoComponent.map)
    })
  }

  addMarkersForControlPoints(controlPoints: ControlPointsSettings) {
    controlPoints?.allowedControlPoints?.forEach(x => {
      // Icon options
      var iconOptions = {
        iconUrl: 'src/assets/cp.png',
        iconSize: [50, 50]
      }
      // Creating a custom icon
      var customIcon = L.icon(iconOptions);
      L.marker([x?.location?.latitude, x?.location?.longitude], {
        icon: L.divIcon({
          html: x.name,
          className: 'text-below-marker',
        })
      })?.addTo(DeviceInfoComponent.map)
      // Creating Marker Options
      var markerOptions = {
        title: x.name,
        clickable: false,
        draggable: false,
        // icon: customIcon
      }
      var marker = new L.Marker([x?.location?.latitude, x?.location?.longitude], markerOptions)
      marker.addTo(DeviceInfoComponent.map)
    })
  }

  getDevice() {
    this.api.getDevice(this.deviceId).subscribe(res => {
      res.lastActiveTime = utcToLocal(res.lastActiveTime, PATTERN_FULL_DATE);
      res.createdDate = utcToLocal(res.lastActiveTime, PATTERN_FULL_DATE);
      res.punchData.punches.sort((a, b) => {
        if (a.timestamp > b.timestamp) return 1;
        if (a.timestamp < b.timestamp) return -1;
        return 0;
      });
      this.device = res;
      this.getDeviceGpsPoints()
    })
  }

  setPolylineColors(line, colors) {

    var latlngs = line.getLatLngs();

    latlngs.forEach(function (latlng, idx) {
      if (idx + 1 < latlngs.length) {
        var poly = L.polyline([latlng, latlngs[idx + 1]], {color: colors[idx]}).addTo(DeviceInfoComponent.map);
        DeviceInfoComponent.map.addLayer(poly);
      }
    })
    DeviceInfoComponent.map.fitBounds(line.getBounds());
  }

  downloadGpx() {
    var sorted = this.gpsTrack.sort((a, b) => {
      if (a.timestamp > b.timestamp) return 1;
      if (a.timestamp < b.timestamp) return -1;
      return 0;
    })

    const createXmlString = (lines: GpsPoint[]): string => {
      let result = '<?xml version="1.0" encoding="UTF-8"?><gpx xmlns="http://www.topografix.com/GPX/1/1" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd" version="1.1" creator="runtracker"><metadata/><trk><name></name><desc></desc>'
      result += '<trkseg>';
      lines.forEach(accum => {
        result += `<trkpt lat="${accum.latitude}" lon="${accum.longitude}"><ele>${accum.altitude}</ele><time>${millisToTimeUtc(+accum.timestamp, PATTERN_UTC)}</time></trkpt>`
      });
      result += '</trkseg>'
      result += '</trk></gpx>';
      return result;
    }

    var s = createXmlString(this.gpsTrack)
    console.log(s)
    const downloadGpxFile = (
      lines: GpsPoint[],
      distance: number[],
    ) => {
      const xml = createXmlString(lines);
      const url = 'data:text/json;charset=utf-8,' + xml;
      const link = document.createElement('a');
      link.download = `${distance[distance.length - 1]}-kilometers.gpx`;
      link.href = url;
      document.body.appendChild(link);
      link.click();
    };
    downloadGpxFile(this.gpsTrack, [1],)
  }

  gpsTrack: GpsPoint[];

  getDeviceGpsPoints() {
    this.api.getDeviceTrack(this.deviceId).subscribe(res => {
      this.gpsTrack = res
      let points = []
      let colors = []
      res.sort((a, b) => {
        if (a.timestamp > b.timestamp) return 1;
        if (a.timestamp < b.timestamp) return -1;
        return 0;
      }).forEach(x => {
          var color = "";
          if (x.speed < 0.1) {
            color = "#fc0303"
          } else if (x.speed > 0.1 && x.speed < 0.3) {
            color = "#fc6f03"
          } else if (x.speed > 0.3 && x.speed < 1) {
            color = "#fcc603"
          } else if (x.speed > 1 && x.speed < 2) {
            color = "#d7fc03"
          } else {
            color = "#39fc03"
          }
          colors.push(color)
          points.push({lat: x.latitude, lng: x.longitude, z: color})
        }
      )
      ;

      //Define an array of Latlng objects (points along the line)
      var polylineOptions = {
        color: 'blue',
        weight: 6,
        opacity: 0.9,
        smoothFactor: 1,
      };

      var customMarker = L.Marker.extend({
        options: {
          speed: '111',
          bearing: '111'
        }
      });

      for (var i = 0, len = points.length; i < len; i++) {
        var m = new customMarker(points[i], {
          // get the actual speed for this lat-long
          // if speeds are in an array in the same exact order as the lat-longs
          // it could be as easy as speed: speed[i]
          speed: '123'
        });
        m.on('click', function () {
          alert(this.options.speed)
        })
        // m.addTo(DeviceInfoComponent.map);
      }
      var polyline = new L.Polyline(points, polylineOptions);
      this.setPolylineColors(polyline, colors);

      //TODO analyze points
      this.analyze()
      // L.hotline(points).addToMap(this.map);

      // this.map.addLayer(polyline);

      // zoom the map to the polyline
      // this.map.fitBounds(polyline.getBounds());
    })
  }

  analyze() {
    console.log("ANALYZE")
    var punchesToCheck: AllowedControlPoint[] = []
    var devicePunches: Punch[] = []
    // console.log(this.device.punchData)
    this.device.punchData.punches.forEach(x => devicePunches.push(x))
    this.device.punchData.deletedPunches.forEach(x => devicePunches.push(x))
    this.controlPointsSettings.allowedControlPoints.forEach((cp: AllowedControlPoint) => {
      var found = devicePunches.find((x: Punch) => x.major == cp.settings.major && x.minor == cp.settings.minor)
      // console.log("CP: " + JSON.stringify(cp))
      // console.log("PP: " + JSON.stringify(found))
      // console.log("F1: " + (found === null))
      // console.log("F1: " + (found == null))
      if (found == null) {
        if (cp.price > 0) {
          punchesToCheck.push(cp)
        }
      }
    })
    var waitingApprove: Punch[] = []
    this.gpsTrack.forEach(gpsPoint => {
      punchesToCheck.forEach(toCheck => {
        let nearestDistance = 10000
        var calculatedDistance = this.calcCrow(gpsPoint.longitude, toCheck.location.longitude, gpsPoint.latitude, toCheck.location.latitude)
        // console.log("CALC: " + calculatedDistance)
        if (calculatedDistance < nearestDistance) {
          nearestDistance = calculatedDistance
        }
        if (nearestDistance <= 2230.116405037903) {
          toCheck.nearestDistance = nearestDistance
          var toAdd = new Punch();
          toAdd.uuid = toCheck.settings.uuid
          toAdd.major = toCheck.settings.major
          toAdd.minor = toCheck.settings.minor
          try {
            toAdd.timestamp = new Date(+gpsPoint.timestamp).toISOString()
          } catch (ex) {
            // console.log(ex)
          }
          toAdd.location = new Location();
          toAdd.location.longitude = gpsPoint.longitude
          toAdd.location.latitude = gpsPoint.latitude
          toAdd.location.accuracy = gpsPoint.accuracy
          toAdd.packetId = "gps-" + gpsPoint.id
          toAdd.nearestDistance = nearestDistance
          toAdd.status = PunchStatus.waiting_approve
          toCheck.status = PunchStatus.waiting_approve
          waitingApprove.push(toAdd)
        }
        //TODO compare nearestDistance with accuracy parameter / distance parameter
        //TODO add to ok or not
      })

// A little bit simplified version

    })
    const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
      arr.reduce((groups, item) => {
        (groups[key(item)] ||= []).push(item);
        return groups;
      }, {} as Record<K, T[]>);

    var grouped = groupBy(waitingApprove, i => i.minor)
    console.log(waitingApprove)
  }

  // Converts numeric degrees to radians
  toRad(Value) {
    return Value * Math.PI / 180;
  }

  //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
  calcCrow(_lat1, _lon1, _lat2, _lon2) {
    var R = 6371; // km
    var dLat = this.toRad(_lat2 - _lat1);
    var dLon = this.toRad(_lon2 - _lon1);
    var lat1 = this.toRad(_lat1);
    var lat2 = this.toRad(_lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  delete(device: Device, p: Punch) {
    this.api.deletePunch(device.id, p).subscribe(res => {
      this.notifyService.showSuccess(res);
      this.getDevice()
    });
  }

  open(content) {
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.createPunch()
    }, (reason) => {
    });
  }

  open2(content) {
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.createPunchNew()
    }, (reason) => {
    });
  }

  createPunch() {
    this.api.getControlPointsSettingsById(this?.controlPointsSettings?.id).subscribe(res => {
      if (res.id != null) {
        let prefs = new AppPreferences();
        prefs.lastControlPointsSettings = res
        this.preferencesService.setAppPreferences(prefs)
      } else {
      }
      //If success - save

    })
    const minors = this.punch.minors.replace(" ", "").split(',');
    const punches = [];
    minors.forEach(x => {
      var p = new Punch();
      //TODO add parsing between cp
      var splitted = x.split("-")
      // if(p.minor == 48) p.minor = 88;
      // if(p.minor == 53) p.minor = 74;
      // if(p.minor == 54) p.minor = 75;
      // if(p.minor == 60) p.minor = 74;
      // if(p.minor == 64) p.minor = 96;
      // if(p.minor == 64) p.minor = 96;
      if (splitted.length == 1) {
        p.minor = +x;
        p.timestamp = this.punch.timestamp;
      } else {
        try {
          p.minor = +splitted[1].toString();
          var a = this.device.punchData.punches?.find(x => x.minor?.toString() == splitted[0])
          var b = this.device.punchData.punches?.find(x => x.minor?.toString() == splitted[2])
          var t1 = new Date(a.timestamp).getTime()
          var t2 = new Date(b.timestamp).getTime()
          var diff = (t2 - t1)
          p.timestamp = new Date(t1 + diff / 2).toISOString();
        } catch (exc) {
          this.notifyService.showError("Incorrect syntax")
        }
      }
      p.major = this.punch.major;
      p.uuid = this.punch.uuid;
      p.packetId = this.punch.packetId;
      punches.push(
        p
      )
    });
    this.api.uploadPunch(this.deviceId, punches).subscribe(res => {
      this.getDevice();
      res.success.forEach(x =>
        this.notifyService.showSuccess(x.message + ' - ' + x.status)
      );
      res.errors.forEach(x =>
        this.notifyService.showError(x.message + ' - ' + x.minor)
      )
    });
  }

  createPunchNew() {
    this.api.getControlPointsSettingsById(this.controlPointsSettings.id).subscribe(res => {
      if (res.id != null) {
        let prefs = this.preferencesService.appPreferences;
        prefs.lastControlPointsSettings = res
        this.preferencesService.setAppPreferences(prefs)
        const minors = this.punch.minors.replace(" ", "").split(',');
        const punches = [];
        minors.forEach(x => {
          var p = new Punch();
          //TODO add parsing between cp
          var splitted = x.split("-")
          if (splitted.length == 1) {
            p.minor = +x;
            p.timestamp = this.punch.timestamp;
          } else {
            try {
              p.minor = +splitted[1].toString();
              var a = this.device.punchData.punches?.find(x => x.minor?.toString() == splitted[0])
              var b = this.device.punchData.punches?.find(x => x.minor?.toString() == splitted[2])
              var t1 = new Date(a.timestamp).getTime()
              var t2 = new Date(b.timestamp).getTime()
              var diff = (t2 - t1)
              console.log("timestamp: " + diff)
              p.timestamp = new Date(t1 + diff / 2).toISOString();
            } catch (exc) {
              this.notifyService.showError("Incorrect syntax")
            }
          }
          p.major = this.punch.major;
          p.uuid = this.punch.uuid;
          p.packetId = this.punch.packetId;
          punches.push(
            p
          )
        });
        punches.forEach(x => {
          var found = this.controlPointsSettings.allowedControlPoints.find(f => f.code == x.minor)
          console.log("FOUND: " + JSON.stringify(found))
          console.log("OLD: " + JSON.stringify(x))
          x.minor = found.settings.minor
          x.uuid = found.settings.uuid
          x.major = found.settings.major
          console.log("REPLACED: " + JSON.stringify(x))
        })
        var newPrefs = this.preferencesService.appPreferences
        console.log(newPrefs)
        console.log(this.controlPointsSettings)
        newPrefs.lastControlPointsSettings = this.controlPointsSettings
        this.preferencesService.setAppPreferences(newPrefs);
        this.api.uploadPunch(this.deviceId, punches).subscribe(res => {
          this.getDevice();
          res.success.forEach(x =>
            this.notifyService.showSuccess(x.message + ' - ' + x.status)
          );
          res.errors.forEach(x =>
            this.notifyService.showError(x.message + ' - ' + x.minor)
          )
        });
      } else {
        console.log("ERROR")
      }
      //If success - save

    })
  }

  restore(device: Device, p: Punch) {
    this.api.restorePunch(device.id, p).subscribe(res => {
      this.getDevice();
      res.success.forEach(x =>
        this.notifyService.showSuccess(x.message + ' - ' + x.status)
      );
      res.errors.forEach(x =>
        this.notifyService.showError(x.message + ' - ' + x.minor)
      )
    });
  }

  copy() {
    this.api.uploadPunch(this.deviceIdForCopy, this.device.punchData.punches).subscribe(res => {
      res.success.forEach(x =>
        this.notifyService.showSuccess(x.message + ' - ' + x.status)
      );
      res.errors.forEach(x =>
        this.notifyService.showError(x.message + ' - ' + x.minor)
      )
    });
  }

  link() {
    this.api.linkDeviceToParticipant(this.device.id, this.participantForLink).subscribe(res => {
      this.notifyService.showSuccess(res.message)
    });
  }

  lockOrUnlock() {
    this.device.locked = !this.device.locked
    this.api.updateDevice(this.device.id, this.device).subscribe(res => {
      if (this?.device?.locked) {
        this.notifyService.showSuccess("Device locked")
      } else {
        this.notifyService.showSuccess("Device unlocked")
      }
    })
  }
}
