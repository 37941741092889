<div class="div-center">

  <div class="table">
    <h5>ID: {{controlPointsSettings?.id}}</h5>
    <div>
      <h5>Название: {{controlPointsSettings?.name}}</h5>
    </div>
    <button class="btn btn-success mr-1" style="margin: 20px;" (click)="update()">Сохранить</button>
    <button class="btn btn-success mr-1" style="margin: 20px;" (click)="openUpdateDialog(contentAddParticipant)">
      Обновить
    </button>
  </div>

  <div>
    <div>
      <table class="table">
        <thead>
        <tr>
          <th>#</th>
          <th>name</th>
          <th>code</th>
          <th>minor</th>
          <th>major</th>
          <th>latitude</th>
          <th>longitude</th>
          <th>price</th>
          <th>beginWorkingTime</th>
          <th>endWorkingTime</th>
          <th>qrHash</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let p of controlPointsSettings?.allowedControlPoints" class="div-center">
          <td>{{controlPointsSettings?.allowedControlPoints?.indexOf(p) + 1}}</td>
          <td>
            <div class="device-details">
              <input type="text" class="form-control" id="name" required [(ngModel)]="p.name" name="name"
                     placeholder="" #name="ngModel">
            </div>
          </td>
          <td>
            <div class="device-details">
              <!--              <p type="text" class="form-control" id="name" required [(ngModel)]="p.code" name="name"-->
              <!--                 placeholder="" #name="ngModel"</p>-->
              <p class="p-base">{{p.code}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <input type="text" class="form-control" id="name" required [(ngModel)]="p.settings.minor" name="name"
                     placeholder="" #name="ngModel">
            </div>
          </td>
          <td>
            <div class="device-details">
              <input type="text" class="form-control" id="name" required [(ngModel)]="p.settings.major" name="name"
                     placeholder="" #name="ngModel">
            </div>
          </td>
          <td>
            <div class="device-details">
              <input type="text" class="form-control" id="name" required [(ngModel)]="p.location.latitude" name="name"
                     placeholder="" #name="ngModel">
            </div>
          </td>
          <td>
            <div class="device-details">
              <input type="text" class="form-control" id="name" required [(ngModel)]="p.location.longitude" name="name"
                     placeholder="" #name="ngModel">
            </div>
          </td>
          <td>
            <div class="device-details">
              <input type="text" class="form-control" id="name" required [(ngModel)]="p.price" name="name"
                     placeholder="" #name="ngModel">
            </div>
          </td>
          <td>
            <div class="device-details">
              <input type="text" class="form-control" id="name" required [(ngModel)]="p.beginWorkingTime" name="name"
                     placeholder="" #name="ngModel">
            </div>
          </td>
          <td>
            <div class="device-details">
              <input type="text" class="form-control" id="name" required [(ngModel)]="p.endWorkingTime" name="name"
                     placeholder="" #name="ngModel">
            </div>
          </td>
          <td>
            <div>
              <div class="text-wrapper">
                {{p.qrHash}}
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


<ng-template #contentAddParticipant let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      Обновление данных
    </h1>
    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="data">Данные</label>
        <textarea type="text" class="form-control" id="data"
                  rows="auto" cols="auto"
                  [(ngModel)]="updateControlPoints.data"
                  placeholder="" name="data"></textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="copyAsCSV()">
      Copy as csv
    </button>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
      Save
    </button>
  </div>
</ng-template>

