import {Component, OnInit} from '@angular/core';
import {NotificationService} from "../../services/notifications/notification.service";

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent implements OnInit {

  constructor(
    private notifyService : NotificationService
  ) { }

  ngOnInit() {
  }

}
