<div class="content">

  <div style="display: inline;">
    <h3>Device id</h3>
    <input type="text" class="form-control" [(ngModel)]="deviceId" placeholder="" name="oldTeamId"
      #name="ngModel">
  </div>

  <div style="display: inline;">
    <h3>Major</h3>
    <input type="text" class="form-control" [(ngModel)]="punch.major" placeholder=""
      name="oldTeamId" #name="ngModel">
  </div>

  <div style="display: inline;">
    <h3>Minor</h3>
    <input type="text" class="form-control" [(ngModel)]="punch.minor" placeholder=""
      name="oldTeamId" #name="ngModel">
  </div>

  <div style="display: inline;">
    <h3>TAG</h3>
    <input type="text" class="form-control" [(ngModel)]="punch.packetId" placeholder=""
      name="oldTeamId" #name="ngModel">
  </div>

  <div style="display: inline;">
    <h3>Timestamp</h3>
    <input type="text" class="form-control" [(ngModel)]="punch.timestamp" placeholder=""
      name="oldTeamId" #name="ngModel">
  </div>

  <div style="margin: 20px;">
    <button type="button" class="btn btn-primary" (click)="createPunch()">Create</button>
  </div>

  <div style="display: inline;">
    <h4>{{logs}}</h4>
  </div>
</div>
