<div class="content">
  <div style="display: inline;">
    <h3 class="title-base-left">Название</h3>
    <input type="text" class="form-control" [(ngModel)]="team.name" placeholder=""
           name="oldTeamId" #name="ngModel">
  </div>

  <div style="display: inline;">
    <h3 class="title-base-left">Bib</h3>
    <input type="text" class="form-control" [(ngModel)]="team.bib" placeholder=""
           name="oldTeamId" #name="ngModel">
  </div>

  <div style="margin: 20px;">
    <button type="button" class="btn btn-primary" (click)="save()">Сохранить</button>
  </div>

  <div class="card">
    <div class="form-group">
      <p for="search-text">Поиск участника</p>
<!--      <input type="email" class="form-control" id="search-text2" aria-describedby="search-text2"-->
<!--             [(ngModel)]="searchText2" placeholder="Enter text to search"-->
<!--             autofocus>-->
    </div>
    <ul class="list-group list-group-flush">
      <li class="item-multiple-buttons" *ngFor="let team of team.participants">
        <div>
          <p>{{team?.id}} | {{team?.name}}</p>
          <div class="close">
            <span class="close-item" (click)="unlinkParticipant(team)"><i class="fa fa-unlink"></i></span>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <div class="card">
    <div class="form-group">
      <p for="search-text">Поиск команды</p>
      <input type="email" class="form-control" id="search-text" aria-describedby="search-text"
             [(ngModel)]="searchText" placeholder="Enter text to search"
             autofocus>
    </div>
    <ul class="list-group list-group-flush">
      <li class="item-multiple-buttons" *ngFor="let team of teamsAndGroups.groups | filterGroups: searchText">
        <div>
          <p>{{team?.id}} | {{team?.name}}</p>
          <div class="close">
            <span class="close-item" (click)="link(team)"><i class="fa fa-link"></i></span>
            <span class="close-item" (click)="unlink(team)"><i class="fa fa-unlink"></i></span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
