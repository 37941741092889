import {Pipe, PipeTransform} from '@angular/core';
import {Team} from "../../domain/models";

@Pipe({
  name: 'filterList'
})
export class FilterListPipe implements PipeTransform {

  transform(items: Team[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter(it => {
      return it?.name?.toLowerCase().includes(searchText) || it?.bib?.toLowerCase().includes(searchText) || it?.id?.includes(searchText);
    });
  }

}
