<script type="text/javascript">
  function showHideRow(row) {
    $("#" + row).toggle();
  }
</script>
<div>
  <table class="table">
    <thead>
      <tr>
        <th>CP</th>
        <th>All punches count</th>
        <th>Admin added count ({{data?.totalAddedAdmin}})</th>
        <th>Gps added count ({{data?.totalAddedGps}})</th>
        <th>QR added count ({{data?.totalAddedQr}})</th>
        <th>First participant</th>
        <th>First timestamp(utc)</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let state of data?.controlPoint" style="background-color: #000000;">
        <tr (click)="state.expanded = !state.expanded" style="background-color: #000000;">
          <td style="background-color: lightgray">{{ state?.name }}</td>
          <td style="background-color: lightgray">{{ state?.allPunches?.length }}</td>
          <td style="background-color: lightgray">{{ state?.adminAdded?.length }}</td>
          <td style="background-color: lightgray">{{ state?.gpsAdded?.length }}</td>
          <td style="background-color: lightgray">{{ state?.qrAdded?.length }}</td>
          <td style="background-color: lightgray">{{ state?.participants[0]?.bib}} - {{ state?.participants[0]?.name}} {{ state?.participants[0]?.surname}}
            - {{ state?.participants[0]?.id}}</td>
          <td style="background-color: lightgray">{{ state?.participants[0]?.newDevice?.punches[0]?.timestamp }}</td>
        </tr>
        <!-- <div class="expand-block"> -->
          <ng-container *ngIf="state.expanded">
            <tr *ngFor="let p of state?.participants"  >
              <td>{{state?.participants?.indexOf(p) + 1}}</td>
              <td></td>
              <td></td>
              <td>{{ p?.bib}} - {{ p?.name}} {{ p?.surname}} - {{ p?.id}}</td>
              <td>{{p?.newDevice?.punches[0]?.timestamp}}</td>
            </tr>
          </ng-container>
        <!-- </div> -->
      </ng-container>
    </tbody>
  </table>
</div>
