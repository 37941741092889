<div class="row">
  <div class="column">
    <app-gps-settings></app-gps-settings>
  </div>
  <div class="column">
    <app-punch-algorithm></app-punch-algorithm>
  </div>
  <div class="column">
    <app-control-points-settings></app-control-points-settings>
  </div>
</div>
